import React, { useState } from 'react';
import IMG from '../../assets/1.jpg'; 
import { FiMapPin } from "react-icons/fi";
import "./Colleges.css"

import { MdOutlineFileDownload } from "react-icons/md";
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar';
import Collegeinfo from './Collegeinfo';
import Scholarships from './Scholarships';
import Fee from './Fee';
import Admissions from './Admissions'
import CollegeCourses from './CollegeCourses';


function Collegedetails() {
  const [activeTab, setActiveTab] = useState('collegeInfo');

  return (
    <>
    <div className='navbar-container'>
    <Navbar/>
    </div>
    <div className='college-details-content-main'>
      <div className="college-details">
        <div className="college-details-card">
          <img src={IMG} alt="University" className="college-details-image" />
          
          <div className="college-details-content">
            <div className="college-details-content1">
              <h2>Technical University of Munich</h2>
              <p className="college-details-address">
                <FiMapPin className="college-details-address-icon" />
                Munich, Germany , Public
              </p>
            </div>
            <div className="college-details-content2">
              <button className="college-details-brochure-button"><MdOutlineFileDownload/>Brochure</button>
              <button className="college-details-rate-button">Rate My Chance</button>
            </div>
          </div>

          <div className="college-details-tabs">
            <button 
              className={`college-details-tab-button ${activeTab === 'collegeInfo' ? 'active' : ''}`} 
              onClick={() => setActiveTab('collegeInfo')}
            >
              College Info
            </button>
            <button 
              className={`college-details-tab-button ${activeTab === 'CollegeCourses' ? 'active' : ''}`} 
              onClick={() => setActiveTab('CollegeCourses')}
            >
              Courses
            </button>
            <button 
              className={`college-details-tab-button ${activeTab === 'scholarships' ? 'active' : ''}`} 
              onClick={() => setActiveTab('scholarships')}
            >
              Scholarships
            </button>
            <button 
              className={`college-details-tab-button ${activeTab === 'Fee' ? 'active' : ''}`} 
              onClick={() => setActiveTab('Fee')}
            >
              Fee
            </button>
            <button 
              className={`college-details-tab-button ${activeTab === 'Admissions' ? 'active' : ''}`} 
              onClick={() => setActiveTab('Admissions')}
            >
              Admissions
            </button>
          </div>

          <div className="college-details-tab-content">
            {activeTab === 'collegeInfo' && (
              <div className="college-details-tab-pane">
               <Collegeinfo/>
              </div>
            )}
            {activeTab === 'CollegeCourses' && (
              <div className="college-details-tab-pane">
                <CollegeCourses/>
              </div>
            )}
               {activeTab === 'scholarships' && (
              <div className="college-details-tab-pane">
                <Scholarships/>
              </div>
            )}
               {activeTab === 'Fee' && (
              <div className="college-details-tab-pane">
                <Fee/>
              </div>
            )}
               {activeTab === 'Admissions' && (
              <div className="college-details-tab-pane">
                <Admissions/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
}

export default Collegedetails;
