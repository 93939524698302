import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import Img from "../../assets/login.png";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { ToastSuccess, ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import "./Login.css";

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  

  const onSubmit = async (data) => {
    try {
      const { mobileNumber } = data;
  
      const isValidMobileNumber = /^\d{10}$/.test(mobileNumber);
      if (!isValidMobileNumber) {
        ToastError("Please enter a valid 10-digit mobile number.");
        return;
      }
  
      const res = await Services.LoginSendOTP(
        "POST",
        JSON.stringify({ mobileNumber })
      );
  
      if (res.Status === 1) {
        ToastSuccess("OTP sent successfully. Please verify your Mobile Number.");
        localStorage.setItem("mobile", mobileNumber);
        navigate("/OTPVerifyLogin");
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
      console.log(err);
    }
  };
  

  return (
    <div className="Login">
      <div className="login-img-container">
        <img src={Img} alt="img" className="login-img" />
      </div>

      <div className="login-form">
        <h1>VeTEACH</h1>
        <h2>Login 👋</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. Ipsum fames fusce id sit mi
          tellus non viverra.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-form-details">
            <label className="label-form">Phone Number</label>
            <input
              className="label-form-input"
              type="number"
              {...register("mobileNumber", { required: true })}
            />
            {errors.mobileNumber && <span>Phone Number is required</span>}
            <button type="submit" className="Navbar-login">
              Get OTP <MdOutlineArrowRightAlt className="arrow-icon" />
            </button>
          </div>
        </form>
        <div className="create-account">
          <span>Don't have an account?</span>
          <Link to="/register" className="forgotpassword">
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;



