import React, { useState } from 'react';
import { FaFilter } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

const Filter = ({ filterData, onFilterChange }) => {
  const [sectionStates, setSectionStates] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    specializations: [],
    exams: [],
    firstYearFees: [],
    countries: [],
    offersScholarship: false,
    publiclyFunded: false,
    withAccommodation: false,
    threeYearDegreeAcceptable: false,
    intakes: [],
    courseDuration: [],
    workExperience: [],
  });

  const toggleSection = (index) => {
    setSectionStates({
      ...sectionStates,
      [index]: !sectionStates[index]
    });
  };

  const handleCheckboxChange = (filterCategory, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedCategory = prevFilters[filterCategory]?.includes(value)
        ? prevFilters[filterCategory].filter((item) => item !== value)
        : [...(prevFilters[filterCategory] || []), value];

      const newFilters = {
        ...prevFilters,
        [filterCategory]: updatedCategory
      };

      onFilterChange(getFilteredData(newFilters));

      return newFilters;
    });
  };

  const handleToggleChange = (filterCategory) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [filterCategory]: !prevFilters[filterCategory]
      };

      onFilterChange(getFilteredData(newFilters));
      return newFilters;
    });
  };

  const getFilteredData = (filters) => {
    const filteredData = {};
    Object.keys(filters).forEach((key) => {
      if (Array.isArray(filters[key]) && filters[key].length > 0) {
        filteredData[key] = filters[key];
      } else if (typeof filters[key] === 'boolean') {
        filteredData[key] = filters[key] ? 1 : 0;
      }
    });
    return filteredData;
  };

  const handleClearFilters = () => {
    setSelectedFilters({
      specializations: [],
      exams: [],
      firstYearFees: [],
      countries: [],
      offersScholarship: false,
      publiclyFunded: false,
      withAccommodation: false,
      threeYearDegreeAcceptable: false,
      intakes: [],
      courseDuration: [],
      workExperience: [],
    });
    onFilterChange({}); // Resetting filters
  };

  return (
    <div className="filter-sidebar">
      <div className='filter-buttons'>
        <div className='allfilter'>
          <FaFilter className='allfilter-icon' />
          All Filters
        </div>
        <div className='clear' onClick={handleClearFilters}>Clear All</div>
      </div>

      {filterData.map((section, index) => (
        <div key={index} className="filter-section">
          <div onClick={() => toggleSection(index)} className='filter-section-heading'>
            <h3 className='label-section-heading'>{section.heading}</h3>
            <p>{sectionStates[index] ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
          </div>

          {sectionStates[index] && (
            <div className='filter-label-container'>
              {section.options.map((option, i) => (
                <label key={i} className='filter-label-name'>
                  <input
                    type="checkbox"
                    checked={selectedFilters[section.filterKey]?.includes(option.value)}
                    onChange={() => handleCheckboxChange(section.filterKey, option.value)}
                  />
                  <span className={selectedFilters[section.filterKey]?.includes(option.value) ? 'selected' : ''}>
                    {`${option.label} (${option.count})`}
                  </span>
                </label>
              ))}
              

              {section.filterKey === 'offersScholarship' && (
                <label className='filter-label-name'>
                  <input
                    type="checkbox"
                    checked={selectedFilters.offersScholarship}
                    onChange={() => handleToggleChange('offersScholarship')}
                  />
                  Offers Scholarship
                </label>
              )}
              {section.filterKey === 'publiclyFunded' && (
                <label className='filter-label-name'>
                  <input
                    type="checkbox"
                    checked={selectedFilters.publiclyFunded}
                    onChange={() => handleToggleChange('publiclyFunded')}
                  />
                  Publicly Funded
                </label>
              )}
              {section.filterKey === 'withAccommodation' && (
                <label className='filter-label-name'>
                  <input
                    type="checkbox"
                    checked={selectedFilters.withAccommodation}
                    onChange={() => handleToggleChange('withAccommodation')}
                  />
                  With Accommodation
                </label>
              )}
              {section.filterKey === 'threeYearDegreeAcceptable' && (
                <label className='filter-label-name'>
                  <input
                    type="checkbox"
                    checked={selectedFilters.threeYearDegreeAcceptable}
                    onChange={() => handleToggleChange('threeYearDegreeAcceptable')}
                  />
                  3 Year Degree Acceptable
                </label>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Filter;
