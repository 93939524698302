import React, { useState, useEffect } from 'react';
import { FaExpand, FaArrowRight } from 'react-icons/fa';
import { PiNotePencilFill } from 'react-icons/pi';
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Link } from 'react-router-dom';
import writing from "../../../src/assets/writing.png";


const tasks = [
  {
    id: 'task1',
    title: 'Writing Task 1',
    time: 20,
    description: `
      <span class="highlight">You should spend about 20 minutes on this task.</span>
      <br />
      The graph below shows the production levels of the main kinds of fuel in the UK between 1981 and 2000.
      Summarize the formation by selecting and reporting the main features and make comparisons where relevant.
      <br />
      <span class="highlight">Write at least 150 words.</span>
    `,
    imageUrl: writing,
    placeholder: 'Write at least 150 words.',
    minWords: 150
  },
  {
    id: 'task2',
    title: 'Writing Task 2',
    time: 40,
    description: `
      <span class="highlight">You should spend about 20 minutes on this task.</span>
      <br />
      Write about the following topic:
      <br />
      In some countries university students live at home with their family while they study, whereas in other countries students attend university in another city.
      Do you think the benefits of living away from home during university outweigh the disadvantages?
      Give reasons for your answer and include any relevant examples from your own knowledge or experience.
      <br />
      <span class="highlight">Write at least 250 words.</span>
    `,
    placeholder: 'Write at least 250 words.',
    minWords: 250
  }
];

const Writing = () => {
  const [timeRemaining, setTimeRemaining] = useState(2400); // 40 minutes for the quiz
  const [taskTexts, setTaskTexts] = useState({ task1: '', task2: '' });
  const [activeTab, setActiveTab] = useState('task1'); // Manage active tab state

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleTextChange = (e, taskId) => {
    const text = e.target.value;
    setTaskTexts((prevTexts) => ({ ...prevTexts, [taskId]: text }));
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}m ${secs}s`;
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const wordCount = (text) => {
    return text.trim().split(/\s+/).length;
  };

  return (
    <>
      <div className="navbar-container">
        <Navbar />
      </div>
      <div className="writing-container">
        <div className="audio-toolbar">
          <div className="audio-time">{formatTime(timeRemaining)} remaining</div>
          <div className="audio-icons-top">
            <FaExpand className="audio-icon" onClick={toggleFullScreen} />
          </div>
          <div className="audio-buttons-top">
            <button className="audio-button-preview">
              <PiNotePencilFill />
              Preview
            </button>
            <button className="audio-button-submit">
              Submit <FaArrowRight />
            </button>
          </div>
        </div>

        <div className="tab-buttons">
          {tasks.map((task) => (
            <button
              key={task.id}
              className={`tab-button ${activeTab === task.id ? 'active' : ''}`}
              onClick={() => setActiveTab(task.id)}
            >
              {task.title}
            </button>
          ))}
        </div>

        {tasks.map((task) =>
          activeTab === task.id ? (
            <div key={task.id} className="task-container">
              <h2>{task.title}</h2>
              <p dangerouslySetInnerHTML={{ __html: task.description }} />
              {task.imageUrl && <img src={task.imageUrl} alt="Task" className="task-image" />}
              <textarea
                className="task-textarea"
                value={taskTexts[task.id]}
                onChange={(e) => handleTextChange(e, task.id)}
                placeholder={task.placeholder}
              />
              <div className="word-count">Word count: {wordCount(taskTexts[task.id])}</div>
            </div>
          ) : null
        )}
      </div>
      <Footer />
    </>
  );
};

export default Writing;
