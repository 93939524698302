import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Services } from "../../Services";
import HomeClasses from "./Home.module.css";
import { ToastError } from "../../Components/utils/ToastMessages";
import { useNavigate } from "react-router-dom";

const ResourceBlog = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const GetResources = async () => {
    try {
      const res = await Services.GetResources("GET", null);
      if (res.Status === 1) {
        setData(res.resources);
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching data");
    }
  };

  useEffect(() => {
    GetResources();
  }, []);

  const handleResourceClick = async (resourceId) => {
    try {
      const res = await Services.GetResourcebyID("GET", null, resourceId);
      if (res.Status === 1) {
        navigate(`/resources/${resourceId}`, { state: { resource: res.resource } });
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching data");
    }
  };

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: true,
    autoplay: false,
     autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={HomeClasses["Resource-Blogs-container"]}>
      <div className={HomeClasses["blogs_header"]}>
        <h1>Resource Blogs</h1>
        <p>Learn the process in simple steps with these guides handpicked for you</p>
      </div>

      <Slider {...settings}>
        {data.map((review) => (
          <div key={review.resourceId} className={HomeClasses["Resource-Blogs-review-items"]} onClick={() => handleResourceClick(review.resourceId)}>
            <div className={HomeClasses["Resource-Blogs-review-imgs"]}>
              <img
                src={review.resourcePhoto}
                alt={review.resourceName}
                className={HomeClasses["Resource-Blogs-review-images"]}
              />
              <div className={HomeClasses["review-name-container"]}>
                <span className={HomeClasses["review-name"]}>
                  {review.resourceName}
                </span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ResourceBlog;
