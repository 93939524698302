import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { RiUploadCloud2Fill } from "react-icons/ri";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import parttimeClasses from "./PartTime.module.css";
import { Link } from "react-router-dom";

function PartTime() {
  const [uploadedFileName, setUploadedFileName] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const filename = file.name;
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/content/get-upload-url?filename=${filename}`
    );
    const responseData = await response.json();

    if (responseData.Status === 1) {
      const response = await fetch(responseData.uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      })
        .then((data) => {
          console.log("data :>> ", data);
          const uploadedFileUrl = new URL(data.url);

          const uploadedFileName =
            uploadedFileUrl.pathname.split("/Applications/")[1];
          setUploadedFileName(uploadedFileName);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
  };

  const onSubmit = async (data) => {
    try {
      const formData = {
        ...data,
        imageKey: uploadedFileName,
      };
      const res = await Services.SubmitApplicationForm(
        "POST",
        JSON.stringify(formData)
      );
      if (res.Status === 1) {
        reset();
        setUploadedFileName("");
        ToastSuccess("Message submitted successfully.");
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <div>
      <Navbar />

      <div className={parttimeClasses["About-Img-container"]}>
        <div className={parttimeClasses["About-container-img-text"]}>
          <div className={parttimeClasses["About-hero-text"]}>
            <h1 className={parttimeClasses["About-hero-text-h1"]}>Part Time</h1>
            <p className={parttimeClasses["About-hero-text-p"]}>
              <Link to="/" className={parttimeClasses["About-Home-link"]}>Home</Link>/<span className={parttimeClasses["About-Home-link-span"]}>Part Time</span>
            </p>
          </div>
        </div>
      </div>

        <div className={parttimeClasses["parttime-job-applications"]}>
          <div className={parttimeClasses["parttime-job-heading"]}>
            <h1>Job Application Form</h1>
            <p>
              Please Fill Out the Form Below to Submit Your Job Application!
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={parttimeClasses["partime-jobs-name"]}>
              <div>
                <label className={parttimeClasses["parttime-jobs-label"]}>First Name</label>
                <input
                  type="text"
                  className={parttimeClasses["partime-name-input"]}
                  placeholder="First Name"
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && <span>First name is required</span>}
              </div>
              <div>
                <label className={parttimeClasses["parttime-jobs-label"]}>Last Name</label>
                <input
                  type="text"
                  className={parttimeClasses["partime-name-input"]}
                  placeholder="Last Name"
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && <span>Last name is required</span>}
              </div>
            </div>

            <div className={parttimeClasses["partime-jobs-name"]}>
              <div>
                <label className={parttimeClasses["parttime-jobs-label"]}>Email Address</label>
                <input
                  type="email"
                  className={parttimeClasses["partime-name-input"]}
                  {...register("email", { required: true })}
                />
                {errors.email && <span>Email is required</span>}
              </div>
              <div>
                <label className={parttimeClasses["parttime-jobs-label"]}>Phone Number</label>
                <input
                  type="tel"
                  className={parttimeClasses["partime-name-input"]}
                  {...register("phone", { required: true })}
                />
                {errors.phone && <span>Phone number is required</span>}
              </div>
            </div>

            <div className={parttimeClasses["partime-jobs-name"]}>
              <div>
                <label className={parttimeClasses["parttime-jobs-label"]}>Country</label>
                <input
                  type="text"
                  className={parttimeClasses["partime-name-input"]}
                  {...register("country", { required: true })}
                />
                {errors.country && <span>Country is required</span>}
              </div>
              <div>
                <label className={parttimeClasses["parttime-jobs-label"]}>Location / City</label>
                <input
                  className={parttimeClasses["partime-name-input"]}
                  type="text"
                  {...register("location", { required: true })}
                />
                {errors.location && <span>Location / City is required</span>}
              </div>
            </div>

            <div className={parttimeClasses["parttime-resume"]}>
              <label htmlFor="resume-upload" className={parttimeClasses["parttime-resume-label"]}>
                <RiUploadCloud2Fill className={parttimeClasses["parttime-resume-icon"]} />
                {!uploadedFileName && <h3>Upload or drag and drop a file</h3>}
              </label>
              <input
                type="file"
                id={parttimeClasses["resume-upload"]}
                {...register("resume", { required: true })}
                onChange={handleFileChange}
              />
              {errors.resume && <span>Resume is required</span>}
            </div>

            <button type="submit" className={parttimeClasses["parttime-submit"]}>
              Submit
            </button>
          </form>
        </div>
    
      <Footer />
    </div>
  );
}

export default PartTime;
