import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Navbar from "../../Components/Navbar/Navbar";
import CoursesClasses from "./Courses.module.css";
import Footer from "../../Components/Footer/Footer";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";

function Testpage() {
  const location = useLocation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    if (location.state && location.state.courseDetails) {
      setCourseDetails(location.state.courseDetails);
    }
  }, [location.state]);

  const onSubmit = async (data) => {
    try {
      const res = await Services.FreeImigation("POST", JSON.stringify(data));
      if (res.Status === 1) {
        ToastSuccess(res.Message);
        // Reset form fields upon successful submission if needed
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <div>
      <Navbar />
      {courseDetails && (
        <div className={CoursesClasses["Coachingpage"]}>
          <div className={CoursesClasses["coachingpage-two"]}>
            <div className={CoursesClasses["Coachingpage-one-container"]}>
              <div className={CoursesClasses["menu"]}>
                <h3 className={CoursesClasses["menu-heading"]}>{courseDetails.courseBlogTitle}</h3>
              </div>
            </div>

            <div className={CoursesClasses["Coachingpage-three-container"]}>
            <div className={CoursesClasses["coachingpage-two-img-container"]}>
              <img
                src={courseDetails.courseBlogPhoto1}
                alt="coachingImg"
                className={CoursesClasses["coachingpage-two-img"]}
              />
            </div>
            <div className={CoursesClasses["coachingpage-two-text"]}>
              <h3>{courseDetails.courseBlogHeading1}</h3>
              <p>{courseDetails.courseBlogBody1}</p>
            </div>
           </div>

            <div className={CoursesClasses["coachingpage-four-container"]}>
              <h3>{courseDetails.courseBlogHeading2}</h3>
              <div>
                <img
                  src={courseDetails.courseBlogPhoto2}
                  alt="IELTS"
                  className={CoursesClasses["coachingpage-four-img"]}
                />
              </div>
            </div>

            <div className={CoursesClasses["coachingpage-four"]}>
              <div className={CoursesClasses["coachingpage-four-text"]}>
                <div className={CoursesClasses["coachingpage-four-text-one"]}>
                  <h4>Listening</h4>
                  <p>{courseDetails.courseBlogListening}</p>
                </div>

                <div className={CoursesClasses["coachingpage-four-text-one"]}>
                  <h4>Speaking</h4>
                  <p>{courseDetails.courseBlogSpeaking}</p>
                </div>
              </div>

              <div className={CoursesClasses["coachingpage-four-text"]}>
                <div className={CoursesClasses["coachingpage-four-text-two"]}>
                  <h4>Reading</h4>
                  <p>{courseDetails.courseBlogReading}</p>
                </div>

                <div className={CoursesClasses["coachingpage-four-text-two"]}>
                  <h4>Writing</h4>
                  <p>{courseDetails.courseBlogWriting}</p>
                </div>
              </div>
            </div>

            <div className={CoursesClasses["coachingpage-four-text-three"]}>
              <h4>IELTS Language Training</h4>
              <p>{courseDetails.courseBlogTraining}</p>
            </div>

            <div className={CoursesClasses["free-immigration-assessment"]}>
              <h2>Free Immigration Assessment</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={CoursesClasses["free-immigration"]}>
                  <div className={CoursesClasses["free-immigration-form"]}>
                    <div className={CoursesClasses["form-row"]}>
                      <input
                        type="text"
                        placeholder="NAME"
                        className={CoursesClasses["free-immigration-input"]}
                        {...register("name", { required: "Name is Required" })}
                      />
                      {errors.name && <span>{errors.name.message}</span>}
                    </div>

                    <div className={CoursesClasses["form-row"]}>
                      <select
                        id="visaType"
                        className={CoursesClasses["free-immigration-input"]}
                        {...register("visaType", { required: "Visa is Required" })}
                      >
                        <option value="">SELECT VISA</option>
                        <option value={1}>Full-time Student Visa</option>
                        <option value={2}>Exchange/short-term Student Visa</option>
                        <option value={3}>Research Student Visa</option>
                      </select>
                      {errors.visaType && <span>{errors.visaType.message}</span>}
                    </div>
                  </div>

                  <div className={CoursesClasses["free-immigration-form"]}>
                    <div className={CoursesClasses["form-row"]}>
                      <input
                        type="text"
                        placeholder="PHONE"
                        className={CoursesClasses["free-immigration-input"]}
                        {...register("phone", { required: "Phone Number is required" })}
                      />
                      {errors.phone && <span>{errors.phone.message}</span>}
                    </div>

                    <div className={CoursesClasses["form-row"]}>
                      <textarea
                        id="message"
                        name="message"
                        placeholder="MESSAGE"
                        className={CoursesClasses["free-immigration-input"]}
                        {...register("message", { required: "Message is required" })}
                      />
                      {errors.message && <span>{errors.message.message}</span>}
                    </div>
                    <div className={CoursesClasses["from_button"]}>
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Testpage;
