import React, { useState, useEffect } from "react";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import "./FreeResources.css";
import ReactPlayer from "react-player";
import Pagination from "react-js-pagination";

function Videos({ videodata, datapages, setDatapages, setVideodata }) {
  const [videos, setVideos] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPageCount, setPageCount] = useState(0);
  const itemsPerPage = 5;

  useEffect(() => {
    getVideos(activePage);
  }, [activePage]);

  const getVideos = async (page) => {
    try {
      const res = await Services.Getvideos("GET", null, itemsPerPage, page);
      if (res.Status === 1) {
        setVideodata(res.videos);
        setDatapages(res.pagination.totalVideosCount);
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <>
      <div className="video-container">
        {videodata.map((video, index) => (
          <div className="video-wrapper" key={index}>
            <ReactPlayer url={video.videoLink} controls width={"100%"} />
            <h3>{video.videoTitle}</h3>
            <p>{video.videoDescription}</p>
          </div>
        ))}
      </div>

      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={datapages}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
}

export default Videos;
