import React from "react";


function Collegeinfo() {
  return (
    <div className="Collegeinfo">
      <h2>British Council Great Scholarship</h2>
      <p>
        What’s new at the University of Kent? Kent University application
        deadlines for international students planning to apply for various
        courses are given below: UG Courses: The application deadline is Aug 20,
        2024 for the Sep 2024 intake Post Graduate Taught Courses: For admission
        to Sep 2024 intake, the application deadline for international
        students is Jul 12, 2024. 
      </p>
    </div>
  );
}

export default Collegeinfo;
