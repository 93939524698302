import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { FaStar } from "react-icons/fa6";
import HomeClasses from "./Testimonial.module.css";
import { useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

function Testimonial() {
  const location = useLocation();
  const { testimonials } = location.state || { testimonials: [] };

  const [currentTestimonials, setCurrentTestimonials] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 9;

  useEffect(() => {
    const indexOfLast = activePage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    setCurrentTestimonials(testimonials.slice(indexOfFirst, indexOfLast));
  }, [activePage, testimonials]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div>
      <Navbar />
     
      <div className="About-Img-container">
        <div className="About-container-img-text">
          <div className="About-hero-text">
            <h1 className="About-hero-text-h1">Testimonial</h1>
            <p className="About-hero-text-p">
              <Link to="/" className="About-Home-link">
                Home
              </Link>
              /
              <span className="About-Home-link-span">Testimonial</span>
            </p>
          </div>
        </div>
      </div>

      <div className={HomeClasses["testimonial-content"]}>
        <div className={HomeClasses["testimonial-container"]}>
        <div className={HomeClasses["testimonial-items"]}>
          {currentTestimonials.map((review, index) => (
            <div key={index} className={HomeClasses["testimonial-item"]}>
              <div className={HomeClasses["testimonial-img-body"]}>
                <img
                  src={review.photo}
                  alt={review.testimonialName}
                  className={HomeClasses["testimonial-img"]}
                />
              </div>
              <div>
                <div className={HomeClasses["testimonial-name-container"]}>
                  <span className={HomeClasses["testimonial-review-name"]}>{review.testimonialName}</span>
                  <span className={HomeClasses["testimonial-review-rating"]}>
                    <FaStar className={HomeClasses["testimonial-review-icon"]} /> {review.testimonialRating}
                  </span>
                </div>
                <p className={HomeClasses["testimonial-review-para"]}>{review.testimonialText}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={HomeClasses["pagination-container"]}>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={testimonials.length}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      </div></div>
      <Footer />
    </div>
  );
}

export default Testimonial;
