export const MenuItems = [
{
    title : "Home",
    url : "/",
    cName : "nav-links",
            
 },
{
    title : "About Us",
    url : "/about",
    cName : "nav-links",
        
},
{
    title : "Colleges",
    url : "/colleges",
    cName : "nav-links",
    
},
{
    title : "Courses",
    url : "/courses",
    cName : "nav-links",
    
},
{
    title : "Blogs",
    url : "/Blogs",
    cName : "nav-links",
    
},
{
    title : "Free Resources",
    url : "/freeresources",
    cName : "nav-links",
    
},
{
    title : "Part Time",
    url : "/Parttime",
    cName : "nav-links",
    
},

{
    title : "Contact",
    url : "/contact",
    cName : "nav-links",
    
},


]