import { toast } from "react-toastify";

export const ToastError = (Message) => {
 toast.error(`${Message}`, {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    zIndex: 10000
  }
 });

}
export const ToastSuccess = (Message) => {
 toast.success(`${Message}`, {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: {
    zIndex: 10000
  }
 });

}
