import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaSkype,FaTwitter } from "react-icons/fa";

const Footer = () => {
    const inputEl = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
  
    const subscribe = () => {
      // Your subscribe logic here
      setLoading(true);
      // Simulated loading delay
      setTimeout(() => {
        setLoading(false);
        alert('Subscribed!');
      }, 2000);
    };
  return (
    <footer className="site-footer">
      <div className="input-group">
        <input
          className="input-email"
          placeholder="Your Email Address"
          ref={inputEl}
          type="email"
        />
        <span className="subscribe-button">
          <button
            onClick={subscribe}
            disabled={loading}
            className={loading ? 'loading' : ''}
          >
            Subscribe
          </button>
        </span>
      </div>
      <div className="footer-content">
        <div className="footer-section1">
          <h5 className='footer-logo'>VeTEACH</h5>
          <p className='footer-content-para'>Lixova provides the simplest solution for processing your all types of visas. Say goodbye to endless hassles and confusions.</p>
          <div className="footer-icons">
            <Link to="#">
              {" "}
              <FaFacebookSquare className="fa fa-facebook" />
            </Link>
            <Link to="#">
              <FaInstagram className="fa fa-twitter" />
            </Link>
            <Link to="#">
              <FaLinkedin className="fa fa-linkedin" />
            </Link>
            <Link to="#">
              <FaSkype className="fa fa-linkedin" />
            </Link>
            <Link to="#">
              <FaTwitter className="fa fa-linkedin" />
            </Link>
          </div>
      
        </div>
        <div className="footer-section">
          <h5 className='footer-heading'>INFORMATION</h5>
          <ul className='footer-para1'>
            <span>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/courses">Courses</Link></li>
            <li><Link to="/colleges">Colleges</Link></li>
          <li><Link to="/freeresources">Free Resources</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
            </span>
            {/* <span>
            <li>Our Team</li>
            <li>Research</li>
            </span> */}
          </ul>
        </div>
        <div className="footer-section">
          <h5 className='footer-heading'>VISA</h5>
          <ul className='footer-para1'>
          
            <li>Students Visa</li>
         
          </ul>
        </div>
        <div className="footer-section">
          <h5 className='footer-heading'>GET IN TOUCH</h5>
            <p className='footer-address'>
            <span className='footer-address-heading'>Address</span>
            <span className='footer-para'>201 India 101010, Ind</span>
            </p>
           <p className='footer-address'>
            <span className='footer-address-heading'>Phone</span>
            <span className='footer-para'>(+91) 84858 48484</span>
            </p>
           <p className='footer-address'>
            <span className='footer-address-heading'>Email Address</span>
            <span className='footer-para'>info@example.com</span>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p className='footer-para'>Copyright © 2024 All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
