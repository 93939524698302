import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "./Colleges.css";
import { FaRupeeSign } from "react-icons/fa";

const FeePage = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const sections = [
    {
      title: "Fees in Indian Rupees (INR)",
      table: {
        headers: ["Course Level", "Annual Tuition Fee in INR"],
        rows: [
          ["UG", "INR 0 - 5.46L"],
          ["PG", "INR 0 - 25.5L"],
        ],
      },
    },
    {
      title: "Fees Structure",
      table: {
        headers: ["Course", "Tuition", "Hostel"],
        rows: [
          ["MS", "INR 0 - 5.46L"," -/- "],
          ["PG", "INR 0 - 5.46L"," -/- "],
        ],
      },
    },
    {
      title: "Off-Campus Accommodation & Transportation Cost",
      table: {
        headers: ["Cost","Cost in INR"],
        rows: [
          ["MS", "INR 54.64K"],
          ["PG", "INR 54.64K"],
        ],
      },
    },
    {
      title: "Health Insurance",
      table: {
        headers: ["Course", "Cost In INR"],
        rows: [
          ["MS", "INR 0 - 5.46L"],
          ["Premium Plan", "INR 0 - 5.46L"],
        ],
      },
    },
  ];

  return (
    <div className="fee-page">
        <div className="fee-section-heading">
            <div >
        <FaRupeeSign  className="fee-section-rupeeIcon"/>
        </div>
        <div>
      <h1>Technical University of Munich</h1>
      <h2>Tuition Fees for International Students</h2>
      </div>
      </div>
      {sections.map((section, index) => (
        <div key={index} className="fee-page-section">
          <div className="fee-section-header" onClick={() => toggleSection(index)}>
            <div >
            
              <h3>Technical University of Munich</h3>
              <h4>{section.title}</h4>
            </div>
            <div className="fee-page-section-icon">
              {openSections[index] ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </div>
          </div>
          {openSections[index] && (
            <div className="fee-page-section-content">
              <table className="fee-page-section-table">
                <thead>
                  <tr>
                    {section.table.headers.map((header, i) => (
                      <th key={i}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section.table.rows.map((row, i) => (
                    <tr key={i}>
                      {row.map((cell, j) => (
                        <td key={j}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FeePage;
