import React from 'react';

function Scholarships() {
    const scholarships = [
        {
            name: "British Council Great Scholarship",
            type: "Merit based",
            amount: "₹10.1 L",
            awardees: 6,
            deadline: "-/-"
        },
        {
            name: "Chevening Scholarships",
            type: "Merit based",
            amount: "₹12 L",
            awardees: 8,
            deadline: "15/01"
        },
        {
            name: "Fulbright-Nehru Master's Fellowships",
            type: "Merit based",
            amount: "₹20 L",
            awardees: 10,
            deadline: "15/07"
        },
        {
            name: "Rhodes Scholarship",
            type: "Merit based",
            amount: "₹22 L",
            awardees: 5,
            deadline: "31/08"
        },
        {
            name: "Commonwealth Scholarship",
            type: "Merit based",
            amount: "₹18 L",
            awardees: 7,
            deadline: "28/02"
        },
        {
            name: "Commonwealth Scholarship",
            type: "Merit based",
            amount: "₹18 L",
            awardees: 7,
            deadline: "28/02"
        },
        {
            name: "Commonwealth Scholarship",
            type: "Merit based",
            amount: "₹18 L",
            awardees: 7,
            deadline: "28/02"
        },
    ];

    return (
        <div className="scholarships">
            {scholarships.map((scholarship, index) => (
                <div key={index} className='scholarships-container'>
                    <h3 className='scholarships-heading'>{scholarship.name}</h3> 
                    <table className="scholarship-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type of Scholarship</th>
                                <th>Maximum amount</th>
                                <th>No of Awardees</th>
                                <th>Application Deadline</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{scholarship.name}</td>
                                <td>{scholarship.type}</td>
                                <td>{scholarship.amount}</td>
                                <td>{scholarship.awardees}</td>
                                <td>{scholarship.deadline}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
}

export default Scholarships;