import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "./Colleges.css";

const CollegeCourses = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const sections = [
    {
      title: "Table of Content",
      table: {
        headers: ["Courses", "Duration & Eligibility"],
        rows: [
          ["MS", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["M.Sc.", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["MBA/PGDM", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
        ],
      },
    },
    {
      title: "Technical University of Munich Courses & Fees 2021",
      table: {
        headers: ["Courses", "Duration & Eligibility"],
        rows: [
          ["MS", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["M.Sc.", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["MBA/PGDM", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
        ],
      },
    },
    {
      title: "Technical University of Munich Courses & Fees 2022",
      table: {
        headers: ["Courses", "Duration & Eligibility"],
        rows: [
          ["MS", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["M.Sc.", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["MBA/PGDM", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
        ],
      },
    },
    {
      title: "Technical University of Munich Courses & Fees 2023",
      table: {
        headers: ["Courses", "Duration & Eligibility"],
        rows: [
          ["MS", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["M.Sc.", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["MBA/PGDM", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
        ],
      },
    },
    {
      title: "Technical University of Munich Courses & Fees 2024",
      table: {
        headers: ["Courses", "Duration & Eligibility"],
        rows: [
          ["MS", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["M.Sc.", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
          ["MBA/PGDM", "Duration: 2 years\nExam Accepted: IELTS: 6.5 & Above, TOEFL: 88 & Above, PTE: 65 & Above"],
        ],
      },
    },
    // Add other sections similarly
  ];

  return (
    <div className="fee-page">
    
      <div className="fee-page-section">
        <div className="fee-section-header" onClick={() => toggleSection(0)}>
          <div>
          <h1>Technical University of Munich Courses & Fees 2025</h1>
            <h4 className="fee-section-title">Table of Content</h4>
          </div>
          <div className="fee-page-section-icon">
            {openSections[0] ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </div>
        </div>
        {openSections[0] && (
          <div className="fee-page-section-content">
            <ul className="table-of-contents">
              {sections.slice(1).map((section, index) => (
                <li key={index}>
                  <a href={`#section-${index + 1}`}>{section.title}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {sections.slice(1).map((section, index) => (
        <div key={index} id={`section-${index + 1}`} className="fee-page-section">
          <div className="fee-section-header" onClick={() => toggleSection(index + 1)}>
            <div>
              <h4 className="fee-section-title">{section.title}</h4>
            </div>
            <div className="fee-page-section-icon">
              {openSections[index + 1] ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </div>
          </div>
          {openSections[index + 1] && (
            <div className="fee-page-section-content">
              <p className="fee-page-section-paragraph">The Technical University of Munich is among the popular universities for international students to study in Germany. Many of the Technical University of Munich courses are free for international students as TUM didn't charge any fees for them. The Technical University of Munich fees for international students vary between INR 0 and INR 18 L as per the program. Check the below table for more details about the Technical University of Munich fees for international students:</p>
              <table className="fee-page-section-table">
                <thead>
                  <tr>
                    {section.table.headers.map((header, i) => (
                      <th key={i}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section.table.rows.map((row, i) => (
                    <tr key={i}>
                      {row.map((cell, j) => (
                        <td key={j}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CollegeCourses;
