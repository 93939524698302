import React, { useEffect, useState } from "react";
import HomeClasses from "./Home.module.css";
import { Services } from "../../Services";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { HiUser } from "react-icons/hi2";
import { LuDot } from "react-icons/lu";
import { IoIosArrowRoundForward } from "react-icons/io";

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const truncateText = (text, length) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

const BlogCard = ({
  metaUrl,
  blogTitle,
  blogDescription,
  createdAt,
  author,
  blogPhoto,
}) => {
  const navigate = useNavigate();

  const handleclick = (metaUrl) => {
    navigate(`/Blogs/${metaUrl}`);
  };

  return (
    <div className={HomeClasses["Blog-card"]} onClick={() => handleclick(metaUrl)}>
      <div className={HomeClasses["Blog-card-image"]}>
        <img
          src={blogPhoto}
          className={HomeClasses["blog-img"]}
          alt="blog-img"
        />
      </div>
      <div className={HomeClasses["Blog-card-content"]}>
        <div className={HomeClasses["Blog-card-details"]}>
          <HiUser className="Blog-card-icon" />
          <span className={HomeClasses["Blog-card-author"]}>{author}</span>
          <LuDot className="Blog-card-icon" />
          <span className={HomeClasses["Blog-card-date"]}>{formatDate(createdAt)}</span>
        </div>
        <div className='Blog-card-title-des'>
          <h3 className={HomeClasses["Blog-card-title"]}>{truncateText(blogTitle, 50)}</h3>
          <p className={HomeClasses["Blog-card-description"]}>
            {truncateText(parse(blogDescription), 50)}
          </p>
        </div>
        <Link to="#" onClick={() => handleclick(metaUrl)} className={HomeClasses["Blog-card-read-more"]}>
          Read More <IoIosArrowRoundForward className="Blog-card-icon" />
        </Link>
      </div>
    </div>
  );
};

const RecentBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    try {
      const res = await Services.GetRecentBlogs("GET", null, 3); 
      if (res.Status === 1) {
        setBlogs(res.blogs);
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const ViewAllBlogs = () => {
    navigate("/Blogs");
  };

  return (
    <div>
      <div className={HomeClasses["Home-about-heading"]}>
        <div className={HomeClasses["Home-content-button"]}>
          <h1>Recent Blogs</h1>
        </div>
        <div className={HomeClasses["Home-view-button"]}>
          <button
            type="button"
            className={HomeClasses["blog_bt"]}
            onClick={() => ViewAllBlogs()}
          >
            View All
          </button>
        </div>
      </div>
      <div className={HomeClasses["Blog-card-row"]}>
        {blogs.slice(0, 3).map((blog, index) => (
          <BlogCard key={index} metaUrl={blog.metaUrl} {...blog} />
        ))}
      </div>
    </div>
  );
};

export default RecentBlogs;
