import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./FreeResources.css";
import Ebooks from "./Ebooks";
import Videos from "./Videos";
import { MdComputer, MdVideoLibrary } from "react-icons/md";
import { GiWhiteBook } from "react-icons/gi";
import Webinar from "./Webinar";
import { Services } from "../../Services";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Link } from "react-router-dom";

function FreeResources() {
  const [activeTab, setActiveTab] = useState("ebooks");
  const [categories, setcategories] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [webfilterData, setWebfilterData] = useState([]);
  const [dataCount, setDatCount] = useState(0);
  const [webcategories, setWebcategories] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videodata, setVideodata] = useState([]);
  const [datapage, setDatapage] = useState(0);
  const [datapages, setDatapages] = useState(0);
  const [activeCategory, setActiveCategory] = useState(null);

  const itemsPerPage = 10;

  const EBookCategories = async () => {
    try {
      const res = await Services.GetCategories("GET", null)
      if (res.Status === 1) {
        setcategories(res.ebooksCategories);
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong  ")
    }
  }

  const webCategories = async () => {
    try {
      const res = await Services.WebnirCategories("GET", null)
      if (res.Status === 1) {
        setWebcategories(res.webinarsCategories);
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong  ")
    }
  }


  const videoCategories = async () => {
    try {
      const res = await Services.videoCategories("GET", null)
      if (res.Status === 1) {
        setVideos(res.videosCategories);
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong  ")
    }
  }

  const CategoriesByID = async (Page, id) => {
    try {
      const res = await Services.CategoryByID("GET", null, Page, itemsPerPage, id)
      if (res.Status === 1) {
        setFilterData(res.ebooks);
        setDatCount(res.pagination.totalEbooksCount)
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong  ")
    }
  }

  const webCategoriesByID = async (Page, id) => {
    try {
      const res = await Services.WebnirCategoryByID("GET", null, Page, itemsPerPage, id)
      if (res.Status === 1) {
        setWebfilterData(res.webinars);
        setDatapage(res.pagination.totalWebinarsCount)
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong  ")
    }
  }

  const videoCategoriesByID = async (Page, id) => {
    try {
      const res = await Services.VideoCategoryByID("GET", null, Page, itemsPerPage, id)
      if (res.Status === 1) {
        setVideodata(res.videos);
        setDatapages(res.pagination.totalVideosCount)
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong  ")
    }
  }

  useEffect(() => {
    EBookCategories();
    webCategories();
    videoCategories();
  }, []);

  return (
    <>
      <Navbar />

      <div className="About-Img-container">
        <div className="About-container-img-text">
          <div className="About-hero-text">
            <h1 className="About-hero-text-h1">Free Resources</h1>
            <p className="About-hero-text-p">
              <Link to="/" className="About-Home-link">
                Home
              </Link>
              /
              <span className="About-Home-link-span">Free Resources</span>
            </p>
          </div>
        </div>
      </div>

      <div className="Free-Resources">
        <div className="Free-Resources-heading">
          <div
            className={`free-Resource-header ${activeTab === "ebooks" ? "active" : ""
              }`}
            onClick={() => { setActiveTab("ebooks"); }}
          >
            <GiWhiteBook className="free-resources-icon" />
            eBOOKS
          </div>
          <div
            className={`free-Resource-header ${activeTab === "webinar" ? "active" : ""
              }`}
            onClick={() => setActiveTab("webinar")}
          >
            <MdComputer className="free-resources-icon" />
            Webinar
          </div>
          <div
            className={`free-Resource-header ${activeTab === "videos" ? "active" : ""
              }`}
            onClick={() => setActiveTab("videos")}
          >
            <MdVideoLibrary className="free-resources-icon" />
            VIDEOS
          </div>
        </div>
        <div className="free-Resource-content">
          {activeTab === "ebooks" && (
            <div className="content">
              <div className="category_buttons">
                {categories.map((category, ebookCategoryId) => {
                  return (
                    <div key={category.ebookCategoryId} className="button">
                      <button
                        type="button"
                        className={activeCategory === category.ebookCategoryId ? "active" : ""}
                        onClick={() => {
                          setActiveCategory(category.ebookCategoryId);
                          CategoriesByID(1, category.ebookCategoryId);
                        }}
                      >
                        {category.ebookCategoryName}
                      </button>
                    </div>
                  )
                })}
              </div>
              <Ebooks filterData={filterData} dataCount={dataCount} setDatCount={setDatCount} setFilterData={setFilterData} />
            </div>
          )}
          {activeTab === "webinar" && (
            <div className="content">
              <div className="category_buttons">
                {webcategories.map((category) => {
                  return (
                    <div key={category.webinarCategoryId} className="button">
                      <button
                        type="button"
                        className={activeCategory === category.webinarCategoryId ? "active" : ""}
                        onClick={() => {
                          setActiveCategory(category.webinarCategoryId);
                          webCategoriesByID(1, category.webinarCategoryId);
                        }}
                      >
                        {category.webinarCategoryName}
                      </button>
                    </div>
                  )
                })}
              </div>
              <Webinar webfilterData={webfilterData} setWebfilterData={setWebfilterData} setDatapage={setDatapage} datapage={datapage} />
            </div>
          )}
          {activeTab === "videos" && (
            <div className="content">
              <div className="category_buttons">
                {videos.map((category) => {
                  return (
                    <div key={category.videoCategoryId} className="button">
                      <button
                        type="button"
                        className={activeCategory === category.videoCategoryId ? "active" : ""}
                        onClick={() => {
                          setActiveCategory(category.videoCategoryId);
                          videoCategoriesByID(1, category.videoCategoryId);
                        }}
                      >
                        {category.videoCategoryName}
                      </button>
                    </div>
                  )
                })}
              </div>
              <Videos setDatapages={setDatapages} setVideodata={setVideodata} videodata={videodata} datapages={datapages} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FreeResources;
