import React from "react";
import { Helmet } from "react-helmet-async";

export default function Seo({ metaTitle, metaDescription, metaKeywords }) {
    console.log('metaTitle', metaTitle)
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title> {`${metaTitle}`} </title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
    </Helmet>
  );
}
