export const countries = [
    "United States of America",
    "Australia",
    "Canada",
    "United Kingdom",
    "New Zealand",
    "Singapore",
    "Dubai",
    "Ireland",
    "Germany",
    "France",
    "Sweden",
    "Netherlands",
    "Austria",
    "Denmark",
    "Finland",
    "Italy",
    "Hungary",
    "Switzerland",
    "Spain",
    "Lithuania",
    "Cyprus",
    "Poland",
    "Malaysia",
    "Mauritius",
    "China",
    "Vietnam",
    "Malta",
    "Japan",
    "Belgium",
    "Russia",
    "South Korea",
    "India",
    "Georgia",
    "Monaco",
];

export const educationBoards = [
    "ICSE",
    "CBSE",
    "IGCSE",
    "IBMYP",
    "NIOS",
    "ANDHRA PRADESH (BSEAP/BIEAP)",
    "ASSAM (SEBA/AHSEC)",
    "BIHAR (BSEB)",
    "CHHATTISGARH (CGBSE)",
    "GOA (GBSHSE)",
    "GUJARAT (GSEB)",
    "HARYANA (HBSE)",
    "HIMACHAL (HPBOSE)",
    "JAMMU AND KASHMIR (JKBOSE)",
    "JHARKHAND (JAC)",
    "KARNATAKA (KSEEB/PUC)",
    "KERALA (KBPE)",
    "MAHARASHTRA (MSBSHSE)",
    "MADHYA PRADESH (MPBOSE)",
    "MANIPUR (BSEM/COHSEM)",
    "MEGHALAYA (MBOSE)",
    "MIZORAM (MBSE)",
    "NAGALAND (NBSE)",
    "ODISHA (BSE/CHSE)",
    "PUNJAB (PSEB)",
    "RAJASTHAN (RBSE)",
    "TAMIL NADU (TNSE/TNHSE)",
    "TELANGANA (TBSE)",
    "TRIPURA (TBSE)",
    "UTTAR PRADESH (UP BOARD)",
    "UTTARAKHAND (UBSE)",
    "WEST BENGAL (WBBSE)",
    "OTHER STATE BOARDS"
];
