import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import CountUp from "react-countup";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import { Link } from "react-router-dom";
import AboutClasses from "./About.module.css";

function About() {
  const [teachers, setTeachers] = useState([]);
  const [aboutUs, setAboutUs] = useState({
    content_heading: "",
    content_body: "",
    content_photo: "",
    content_sections: [],
    stats: [],
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await Services.GetAboutUs("GET", null);
      if (res.Status === 1) {
        setTeachers(res.teachers);
        setAboutUs(res.aboutUs);
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <div className={AboutClasses["About-Img-container"]}>
        <div className={AboutClasses["About-container-img-text"]}>
          <div className={AboutClasses["About-hero-text"]}>
            <h1 className={AboutClasses["About-hero-text-h1"]}>About Us</h1>
            <p className={AboutClasses["About-hero-text-p"]}>
              <Link to="/" className={AboutClasses["About-Home-link"]}>Home</Link>/<span className={AboutClasses["About-Home-link-span"]}>About Us</span>
            </p>
          </div>
        </div>
      </div>

      <div className={AboutClasses["about-one"]}>
        <h2 className={AboutClasses["about-heading"]}>About Us</h2>
        <div className={AboutClasses["about-us-container"]}>
          <div className={AboutClasses["about-text-section"]}>
            <h3 className={AboutClasses["about-one-heading"]}>{aboutUs.content_heading}</h3>
            <h1 className={AboutClasses["about-one-heading-large"]}>{aboutUs.content_heading}</h1>
            <p className={AboutClasses["about-one-para"]}>{aboutUs.content_body}</p>
          </div>
          <div className={AboutClasses["About-one-image-section"]}>
            <img src={aboutUs.content_photo} alt="About Us" className={AboutClasses["about-img"]} />
          </div>
        </div>
      </div>

      <div className={AboutClasses["about-two-container"]}>
        {aboutUs.content_sections.map((section, index) => (
          <div className={AboutClasses["about-two-item"]} key={index}>
            <div className={AboutClasses["About-number-container"]}>0{index + 1}</div>
            <h1 className={AboutClasses["about-two-heading"]}>{section.sectionHeading}</h1>
            <p className={AboutClasses["about-two-paragraph"]}>{section.sectionBody}</p>
          </div>
        ))}
      </div>

      <div className={AboutClasses["statistics-container"]}>
        {aboutUs.stats.map((stat, index) => (
          <div className={AboutClasses["statistic"]} key={index}>
            <CountUp end={parseInt(stat.body.replace(/[^\d]/g, ''))} separator="," />+
            <div>{stat.title.toUpperCase()}</div>
          </div>
        ))}
      </div>

      <div className={AboutClasses["teachers-section"]}>
        <h3 className={AboutClasses["teacher-heading-small"]}>Featured Experts</h3>
        <h2 className={AboutClasses["teacher-heading"]}>Meet Our Experts</h2>
        <div className={AboutClasses["teacher-cards"]}>
          {teachers.map((teacher, index) => (
            <div className={AboutClasses["teacher-card-item"]} key={index}>
              <img src={teacher.photoId} alt={teacher.teacherName} />
              <h3>{teacher.teacherName}</h3>
              <p>{teacher.job}</p>
              <p className={AboutClasses["teacher-card-content"]}>{teacher.teacherBio}</p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default About;
