import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { ToastSuccess, ToastError } from "../../Components/utils/ToastMessages";
import RegisterImg from "../../assets/register.png";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";

function OTPVerifyLogin() {
  const [enteredOTP, setEnteredOTP] = useState("");
  const navigate = useNavigate();

  const LoginVerifyOTP = async () => {
    try {
      const mobileNumber = localStorage.getItem("mobile");
      const res = await Services.LoginVerifyOTP(
        "POST",
        JSON.stringify({
          mobileNumber: mobileNumber,
          otp: enteredOTP,
        })
      );
      if (res.Status === 1) {
        ToastSuccess("Registration successful.");
        localStorage.setItem("token", res.token);
        navigate("/");
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      LoginVerifyOTP();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [enteredOTP]);

  return (
    <div className="Login">
      <div className="login-img-container">
        <img src={RegisterImg} alt="img" className="login-img" />
      </div>

      <div className="login-form">
        <h2>Verify Phone Number</h2>
        <p>
          Please check your phone number and enter the verification code below
        </p>
        <div className="otp-input-container">
          <OtpInput
            numInputs={6}
            value={enteredOTP}
            onChange={setEnteredOTP}
            isInputNum={true}
            separator={<span>-</span>}
            inputStyle={{
              width: "40px",
              height: "40px",
              fontSize: "16px",
              textAlign: "center",
              marginRight: "10px",
            }}
            containerStyle={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            renderInput={(props) => (
              <input
                {...props}
                type="text"
                maxLength="1"
                style={{
                  width: "40px",
                  height: "40px",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              />
            )}
          />
        </div>
        <div className="verify-button-container">
          <button
            type="submit"
            className="Navbar-login"
            onClick={LoginVerifyOTP}
          >
            Verify OTP <MdOutlineArrowRightAlt />
          </button>
        </div>
      </div>
    </div>
  );
}

export default OTPVerifyLogin;
