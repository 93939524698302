import React, { useState } from "react";
import "./Contact.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import Phone from "../../assets/phone.png";
import Map from "../../assets/map.png";
import Email from "../../assets/email.png";
import { FaUser, FaEnvelope, FaPencilAlt, FaPaperPlane } from "react-icons/fa";
import { ToastSuccess, ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import { Link } from "react-router-dom";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await Services.ContactPage("POST", JSON.stringify(formData));
      if (res.Status === 1) {
        ToastSuccess("Message submitted successfully.");
        // Optionally clear form fields after successful submission
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <div>
      <Navbar />
        <div className="About-Img-container">
        <div className="About-container-img-text">
          <div className="About-hero-text">
            <h1 className="About-hero-text-h1">Contact</h1>
            <p className="About-hero-text-p">
              <Link to="/" className="About-Home-link">
                Home
              </Link>
              /
              <span className="About-Home-link-span">Contact</span>
            </p>
          </div>
        </div>
      </div>
      <div className="Contact-container">
        <div className="Contact-one-container">
          <div className="Contact-one-number">
            <img src={Phone} alt="phone" className="phone-icon"></img>
            <h6 className="contact-heading">Quick Help</h6>
            <h6 className="contact-value">+91 89854 78267</h6>
          </div>
          <div className="Contact-one-number">
            <img src={Email} alt="email" className="phone-icon"></img>
            <h6 className="contact-heading">Email</h6>
            <h6 className="contact-value">[info@veteach.in]</h6>

            <span className="contact-value">Office Hour: 09:00am - 9:00pm</span>
          </div>
          <div className="Contact-one-number">
            <img src={Map} alt="map" className="phone-icon"></img>
            <h6 className="contact-heading">Location</h6>
            <h6 className="contact-value">
              <span style={{ fontWeight: "400" }}>
                Manjeera Trinity Corporate, Plot No-112, Survey No:1050, JNTU,
                Hitech city Rd, KPHB Colony Kukatpally, Hyderabad-500072
              </span>
            </h6>
          </div>
        </div>
        <div>
        <div style={{width: "100%"}}><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%20%20%20%20%20%20%20%20%20%20%20%20%20%20Manjeera%20Trinity%20Corporate,%20Plot%20No-112,%20Survey%20No:1050,%20JNTU,%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20Hitech%20city%20Rd,%20KPHB%20Colony%20Kukatpally,%20Hyderabad-500072+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe></div>
        </div>
        <div className="contact-form">
          <form onSubmit={handleSubmit} className="contact">
            <h2>Get in touch with us for more information</h2>
            <div className="contact-form-input">
              <div className="contact-input-group">
                <FaUser className="contact-input-icon" />
                <input
                  type="text"
                  name="name"
                  className="contact-input"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                />
              </div>
              <div className="contact-input-group">
                <FaEnvelope className="contact-input-icon" />
                <input
                  type="email"
                  name="email"
                  className="contact-input"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="contact-input-group-one">
              <FaPencilAlt className="input-icon" />
              <input
                type="text"
                name="subject"
                className="contact-input-subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
              />
            </div>
            <div className="contact-input-group-one">
              <FaPaperPlane className="input-icon" />
              <textarea
                name="message"
                value={formData.message}
                className="contact-input-message"
                onChange={handleChange}
                placeholder="Message"
              />
            </div>
            <button type="submit" className="contact-button">
              Submit Message
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
