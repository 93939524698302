import AdvanceClasses from "./AdvanceSearch.module.css";
import React, { useEffect, useState } from "react";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessages";
import { FaGlobeAmericas } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrCurrency } from "react-icons/gr";
import HomeFilter from "./HomeFilter";
import { IoMdPersonAdd } from "react-icons/io";
import { LiaUniversitySolid } from "react-icons/lia";
import ReactPaginate from "react-paginate";
import Switch from "react-switch";
import { TiInputChecked } from "react-icons/ti";
import { useLocation } from "react-router-dom";
import { Services } from "../../Services";
import Modal from "react-modal";


function Searchpage() {
  const [checked, setChecked] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [currentItems, setCurrentItems] = useState([]);
  const [comparisonCourses, setComparisonCourses] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const { state } = useLocation();
  const [shortlistedCourses, setShortlistedCourses] = useState([]);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const handleChange = (isChecked) => {
    setChecked(isChecked);
  };

  useEffect(() => {
    console.log(state.results)
    if (state) {
      const stateResults = state.results;
      setSearchData(state.results);

      setResults(stateResults);
      setFilteredResults(stateResults);
    }
  }, [state]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems =
      filteredResults?.slice(indexOfFirstItem, indexOfLastItem) || [];
    setCurrentItems(currentItems);

    const storedShortlistedCourses = localStorage.getItem("shortlistedCourses");
    if (storedShortlistedCourses) {
      setShortlistedCourses(JSON.parse(storedShortlistedCourses));
    }
  }, [filteredResults, currentPage, itemsPerPage]);

  const handleShortlistToggle = async (course) => {
    if (shortlistedCourses.includes(course)) {
      setShortlistedCourses((prevCourses) =>
        prevCourses.filter((c) => c !== course)
      );
    } else {
      setShortlistedCourses((prevCourses) => [...prevCourses, course]);
      try {
        const formData = {
          courseIds: [...shortlistedCourses, course].map(
            (course) => course.course_id
          ),
        };
        const res = await Services.Shortlistcourses(
          "POST",
          JSON.stringify(formData),
          token
        );
        if (res && res.Status === 1) {
          ToastSuccess("Course shortlisted successfully.");
          // Save shortlisted courses to local storage
          localStorage.setItem(
            "shortlistedCourses",
            JSON.stringify([...shortlistedCourses, course])
          );
        } else {
          ToastError("Something went wrong. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
        ToastError("Something went wrong. Please try again.");
      }
    }
  };

  const handleCompareToggle = (course) => {
    setComparisonCourses((prevCourses) => {
      if (prevCourses.includes(course)) {
        return prevCourses.filter((c) => c !== course);
      } else {
        if (prevCourses.length < 5) {
          return [...prevCourses, course];
        } else {
          ToastError("You can only compare up to 5 courses.");
          return prevCourses;
        }
      }
    });
  };

  const openModal = async () => {
    let res;
    try {
      const formData = {
        courseIds: comparisonCourses.map((course) => course.course_id),
      };
      const res = await Services.comparecourses(
        "POST",
        JSON.stringify(formData)
      );
      if (res && res.Status === 1) {
        setModalData(res.courses);
        setModalIsOpen(true);
      } else {
        ToastError(res.Message);
      }
    } catch (error) {
      ToastError(res.Message);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onSubmit = async (courseIds) => {
    let res;
    try {
      const formData = {
        courseIds: courseIds,
      };
      const res = await Services.comparecoursesdownload(
        "POST",
        JSON.stringify(formData)
      );
      if (res) {
        window.open(res);
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      ToastError(res.Message);
    }
  };

  const handleCompare = () => {
    const validCourseIds = comparisonCourses
      .map((course) => course.course_id)
      .filter((courseId) => courseId !== null);

    if (validCourseIds.length > 0) {
      onSubmit(validCourseIds);
    } else {
      ToastError("No valid course IDs found for comparison.");
    }
  };

  const handleDownloadClick = async () => {
    try {
      const formData = {
        results: searchData,
      };
      const res = await Services.DownloadSearchResults("POST", JSON.stringify(formData));
      if (res) {
        window.open(res);
      } else {
        ToastError("Failed to download search results.");
      }
    } catch (error) {
      console.error("Error:", error);
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className={AdvanceClasses["Advance-Search-page"]}>
      <div className={AdvanceClasses["Advance-Search-Coursesfound"]}>
        <div className={AdvanceClasses["Advance-Course-found"]}>
          <h4>
            {filteredResults
              ? `${filteredResults.length} Courses Found`
              : "Loading..."}
          </h4>
          <p>
            Page {currentPage} of{" "}
            {Math.ceil((filteredResults?.length || 1) / itemsPerPage)}
          </p>
        </div>

        <h3 className={AdvanceClasses["Advance-Home-Course-heading"]}>
          <button
            className={AdvanceClasses["Advance-Home-Course-Download"]}
            onClick={handleDownloadClick}
          >
            Download
          </button>

          <div className={AdvanceClasses["Advancesearch-shortlist-heading"]}>
            <TiInputChecked className={AdvanceClasses["Advancesearch-heading-icon-switch"]} />
            Shortlist All
            <Switch
              onChange={handleChange}
              checked={checked}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={16}
              offHandleColor={"#fff"}
              onHandleColor={"#115277"}
              height={18}
              width={32}
              onColor="#fff"
            />
          </div>
        </h3>

        {currentItems.map((course, index) => (
          <div key={index} className={AdvanceClasses["Advance-Search-university-card"]}>
            <div className={AdvanceClasses["Advance-Search-university-card-two"]}>
              <h3 className={AdvanceClasses["Advancesearch-main-heading"]}>
                {course.course_name}
              </h3>
              <div className={AdvanceClasses["Advancesearch-shortlist-buttons"]}>
                <h3 className={AdvanceClasses["Advancesearch-shortlist-heading"]}>
                  <TiInputChecked className={AdvanceClasses["Advancesearch-heading-icon"]} />
                  ShortList
                  <Switch
                    onChange={() => handleShortlistToggle(course)}
                    checked={shortlistedCourses.includes(course)}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={16}
                    height={18}
                    width={32}
                    onColor="#115277"
                  />
                </h3>
                <h3 className={AdvanceClasses["Advancesearch-shortlist-heading"]}>
                  <TiInputChecked className={AdvanceClasses["Advancesearch-heading-icon"]} />
                  Compare
                  <Switch
                    onChange={() => handleCompareToggle(course)}
                    checked={comparisonCourses.includes(course)}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={16}
                    height={18}
                    width={32}
                    onColor="#115277"
                  />
                </h3>
              </div>
            </div>
            <div>
              <div className={AdvanceClasses["Advance-Search-university-card-one"]}>
                <span className={AdvanceClasses["Advancesearch-heading"]}>
                  <LiaUniversitySolid className={AdvanceClasses["Advancesearch-heading-icon"]} />
                  University :{" "}
                  <span className={AdvanceClasses["Advancesearch-heading-answer"]}>
                    {course.college_name}
                  </span>
                </span>
                <span className={AdvanceClasses["Advancesearch-heading"]}>
                  <FaRegClock className={AdvanceClasses["Advancesearch-heading-icon"]}/>
                  Duration :
                  <span className={AdvanceClasses["Advancesearch-heading-answer"]}>
                    {course.course_duration} Months
                  </span>
                </span>
              </div>

              <div className={AdvanceClasses["Advance-Search-university-card-one"]}>
                <span className={AdvanceClasses["Advancesearch-heading"]}>
                  <FaGlobeAmericas className={AdvanceClasses["Advancesearch-heading-icon"]}/>
                  Country :{" "}
                  <span className={AdvanceClasses["Advancesearch-heading-answer"]}>
                    {course.college_country}
                  </span>
                </span>
                <span className={AdvanceClasses["Advancesearch-heading"]}>
                  <GrCurrency className={AdvanceClasses["Advancesearch-heading-icon"]} />
                  Yearly Tuition Fee :{" "}
                  <span className={AdvanceClasses["Advancesearch-heading-answer"]}>
                    {course.currency}
                    {course.course_fee}
                  </span>
                </span>
              </div>

              <div className={AdvanceClasses["Advance-Search-university-card-one"]}>
                <span className={AdvanceClasses["Advancesearch-heading"]}>
                  <IoMdPersonAdd className={AdvanceClasses["Advancesearch-heading-icon" ]}/>
                  Intakes :{" "}
                  <div>
                    <button
                      className={`${AdvanceClasses["Advancesearch-Intake-button"]} ${
                        course.intake_year === "Open"
                          ? AdvanceClasses["Advancesearch-Intake-button-open"]
                          : course.intake_year === "Close"
                          ? AdvanceClasses["Advancesearch-Intake-button-close"]
                          : ""
                      }`}
                    >
                      {course.intake_year}
                    </button>
                  </div>
                </span>
                <span className={AdvanceClasses["Advancesearch-heading"]}>
                  <GrCurrency className={AdvanceClasses["Advancesearch-heading-icon"]}/>
                  Application Fee :{" "}
                  <span className={AdvanceClasses["Advancesearch-heading-answer"]}>
                    {course.currency}
                    {course.application_fee}
                  </span>
                </span>
              </div>

              <div className={AdvanceClasses["Advancesearch-main-view"]}>
                <GiHamburgerMenu className={AdvanceClasses["Advancesearch-heading-icon-view"]} />
                View Details
              </div>

              <div className={AdvanceClasses["Advance-search-ranking"]}>
                <span
                  style={{
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: "800",
                  }}
                >
                  {course.ranking}
                </span>{" "}
                Webometrics Canada National Ranking
              </div>
            </div>
          </div>
        ))}

        {/* Pagination */}

        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil((filteredResults?.length || 1) / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={AdvanceClasses["pagination"]}
          activeClassName={AdvanceClasses["active"]}
        />
      </div>

      <div className={AdvanceClasses["Advance-Search-filter"]}>
        <HomeFilter
          results={results}
          filteredResults={filteredResults}
          setFilteredResults={setFilteredResults}
        />
      </div>

      <div className={AdvanceClasses["Compare"]}>
        {comparisonCourses.length > 0 && (
          <div className={AdvanceClasses["Comparison-count"]}>
            <div>
              <h3>{comparisonCourses.length} Courses Add for Compare</h3>
            </div>
            <div>
              {" "}
              <button className={AdvanceClasses["Compare-button"]} onClick={openModal}>
                Compare Courses
              </button>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={AdvanceClasses["searchpage-modal"]}
      >
        <button onClick={closeModal} className={AdvanceClasses["modal-close-button"]}>
          Close
        </button>
        <button onClick={handleCompare} className={AdvanceClasses["modal-download-button"]}>
          Download Excel
        </button>
        <table>
          <thead id={AdvanceClasses["sticky-header"]}>
            <tr>
              {modalData.length > 0 &&
                Object.keys(modalData[0]).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {modalData.map((course, index) => (
              <tr key={index}>
                {Object.values(course).map((value, idx) => (
                  <td key={idx}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </div>
  );
}

export default Searchpage;
