import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "./Colleges.css";

const Admissions = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const sections = [
    {
      title: "Technical University of Munich Courses & Fees 2021",
      table: {
        headers: ["Particulars", "Details"],
        rows: [
          ["Application portal", "TUM Online"],
          ["English language tests accepted", "IELTS / TOEFL / PTE"],
          ["Standardized tests accepted (PG)", "GATE / GRE / GMAT (As applicable)"],
          ["TUM Acceptance Rate", "Around 8% (Source: Unofficial)"],
          ["International application deadlines", "According to specific programs"],
        ],
      },
    },
    // Add other sections similarly
  ];

  return (
    <div className="fee-page">
      <div className="fee-page-section">
        <div className="fee-section-header" onClick={() => toggleSection(0)}>
          <div>
            <h1>Technical University of Munich Courses & Fees 2025</h1>
            <h4 className="fee-section-title">Table of Content</h4>
          </div>
          <div className="fee-page-section-icon">
            {openSections[0] ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </div>
        </div>
        {openSections[0] && (
          <div className="fee-page-section-content">
            <ul className="table-of-contents">
              {sections.map((section, index) => (
                <li key={index}>
                  <a href={`#section-${index}`}>{section.title}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {sections.map((section, index) => (
        <div
          key={index}
          id={`section-${index}`}
          className="fee-page-section"
        >
          <div
            className="fee-section-header"
            onClick={() => toggleSection(index)}
          >
            <div>
              <h4 className="fee-section-title">{section.title}</h4>
            </div>
            <div className="fee-page-section-icon">
              {openSections[index] ? (
                <MdKeyboardArrowUp />
              ) : (
                <MdKeyboardArrowDown />
              )}
            </div>
          </div>
          {openSections[index] && (
            <div className="fee-page-section-content">
              <p className="fee-page-section-paragraph">
                There are two major intakes i.e., Summer and Winter to apply for
                the Technical University of Munich admissions. Applicants can
                apply for Munich admission through the official website.
                Technical University of Munich acceptance rate for international
                students is around 8% as per various unofficial sources. This
                means getting admission to TUM is quite competitive. Applicants
                must complete secondary school for bachelor's admission and a
                bachelor's degree at least for a master's admission. Over 125
                Technical University of Munich courses are available to study
                for international students. Many courses are also offered in
                English at TUM. Some of the study areas at TUM include
                Aerospace, Education, Data Engineering and Analytics,
                Architecture, Computer Science, etc. According to Shiksha
                reviews on TUM Munich, the university gets a 5.0 rating out of
                5.0 for Safety. Check the list of Technical University of Munich
                scholarships for Indian students Technical University of Munich
                Admissions 2024 Highlights Students can follow the Technology
                University of Munich admission process to apply for admission.
                Applicants do not need to pay the Technology University of
                Munich application fee to submit the admission application.
                However, there are some entry requirements that applicants must
                meet. International students whose native language is not
                English must meet the Technology University of Munich IELTS
                requirements. The table below includes the highlights of TUM
                admissions 2024:
              </p>
              <table className="fee-page-section-table">
                <thead>
                  <tr>
                    {section.table.headers.map((header, i) => (
                      <th key={i}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section.table.rows.map((row, i) => (
                    <tr key={i}>
                      {row.map((cell, j) => (
                        <td key={j}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Admissions;
