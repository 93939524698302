import React, { useEffect, useState } from "react";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import { BsDownload } from "react-icons/bs";
import "./FreeResources.css";
import Pagination from "react-js-pagination";

function Ebooks({ filterData, dataCount, setDatCount, setFilterData }) {

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 5;
  const token = { Authorization: `token ${localStorage.getItem("token")}` };



  const GetEbooks = async (page) => {
    try {
      const res = await Services.GetEbooks("GET", null, token, itemsPerPage, page);
      if (res.Status === 1) {
        setFilterData(res.ebooks);
        setDatCount(res.pagination.totalEbooksCount);
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {

    GetEbooks(activePage);
  }, []);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div>
      {filterData.map((ebook, index) => (
        <div key={index} className="ebook-container">
          <div className="ebook-image">
            {/* <IoFolderOpen className='ebook-image-icon'/> */}
            <img
              src={ebook.ebookPhoto}
              alt={`Ebook ${index + 1}`}
              width={100}
              height={100}
            />
          </div>
          <div className="ebook-description">
            <h3>{ebook.ebookName}</h3>
            <p>{ebook.ebookDescription}</p>
          </div>
          <div className="ebook-download">
            <a href={ebook.ebookUrl} download>
              <BsDownload className="ebook-download-icon" />
            </a>
          </div>
        </div>
      ))}

      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={dataCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </div>
  );
}

export default Ebooks;
