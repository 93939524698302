import React, { useState, useEffect, useRef ,useMemo} from "react";
import "./Style.css";
import { useNavigate } from "react-router-dom";
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";

function VerifyQuestions() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const optionsRef = useRef(null);
  const navigate = useNavigate();

  // const token = { Authorization: `token ${localStorage.getItem("token")}` };

  
  const token = useMemo(() => ({
    Authorization: `token ${localStorage.getItem("token")}`
  }), []);

  useEffect(() => {
    const fetchProfileQuestions = async () => {
      try {
        const res = await Services.GetProfileQuestions("GET", null, token);
        if (res.Status === 1) {
          setQuestions(res.Questions);
          setAnswers(res.answers || []);
        } else if (res.Status === 0) {
          ToastError(res.Error);
        }
      } catch (err) {
        ToastError("Something went wrong. Please try again.");
      }
    };

    fetchProfileQuestions();
  }, [token]);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowSuccessMessage(true);
    }
  };

  const handleCompleted = async () => {
    setCompleted(true);
    setShowSuccessMessage(true);
    try {
      const mergedAnswers = questions.map((question, index) => ({
        questionId: question.questionId,
        optionId:
          question.selectedOptionId ||
          (answers[index] && answers[index].optionId),
        answerText:
          question.answerText ||
          (answers[index] && answers[index].answerText) ||
          "",
      }));
      const data = { answers: mergedAnswers };
      const res = await Services.SaveProfileAnswers(
        "POST",
        JSON.stringify(data),
        token
      );
      if (res.Status === 1) {
        ToastSuccess("Profile answers saved successfully.");
        navigate("/");
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      ToastError(
        "Something went wrong while saving profile answers. Please try again."
      );
    }
  };

  const handleNext = () => {
    handleNextQuestion();
    if (currentQuestionIndex < questions.length - 1) {
      const nextQuestionElement = document.getElementById(
        `question-${currentQuestionIndex + 2}`
      );
      if (nextQuestionElement) {
        nextQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const handleOptionChange = (optionId) => {
    const updatedQuestions = [...questions];
    updatedQuestions[currentQuestionIndex].selectedOptionId = optionId;
    setQuestions(updatedQuestions);
  };

  const handleAnswerTextChange = (answerText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[currentQuestionIndex].answerText = answerText;
    setQuestions(updatedQuestions);
  };

  const question = questions[currentQuestionIndex];
  const totalQuestions = questions.length;

  return (
    <div className="verifyquestions">
      <div className="verifyquestions-container">
        <h1>VeTeach</h1>
        <ul className="verifyquestions-container-ul">
          <li>
            <input
              type="checkbox"
              id="emailVerified"
              name="emailStatus"
              value="verified"
              checked
            />
            <label htmlFor="emailVerified" className="emailVerified">
              EMAIL VERIFICATION
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="emailNotVerified"
              name="emailStatus"
              value="notVerified"
            />
            <label htmlFor="emailNotVerified" className="emailNotVerified">
              COMPLETE YOUR PROFILE
            </label>
          </li>
        </ul>

        {showSuccessMessage && (
          <div>Email verification is done.</div>
        )}
        <div>
          ----- {currentQuestionIndex + 1} of {questions.length} -----
        </div>
      </div>

      <div className="verifyquestions-container-two">
        {showSuccessMessage ? (
          <div>Login Success Message</div>
        ) : (
          <div className="questioner-container">
            <div className="left-panel">
              {/* Left panel content */}
              {/* Map through steps or show steps based on currentQuestionIndex */}
            </div>
            <div className="right-panel">
              <div className="question">
                <div className="question-number">
                  Question {currentQuestionIndex + 1} of {totalQuestions}
                </div>
                <div className="question-text">
                  {question ? question.questionText : ""}
                </div>
                <ul ref={optionsRef} className="options-list">
                  {question &&
                    question.questionOptions.map((option, index) => (
                      <li key={index} className="question-option">
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex + 1}-${index}`}
                          name={`option-${currentQuestionIndex + 1}`}
                          value={option.text}
                          checked={
                            question.selectedOptionId === option.optionId
                          }
                          onChange={() => handleOptionChange(option.optionId)}
                        />
                        <label
                          htmlFor={`option-${
                            currentQuestionIndex + 1
                          }-${index}`}
                        >
                          {option.text}
                        </label>
                      </li>
                    ))}
                  {question &&
                    question.hasTextInput &&
                    question.hasTextInput ? (
                      <li className="question-option">
                        <input
                          type="text"
                          className="question-option-input-text-field"
                          value={question.answerText || ""}
                          onChange={(e) =>
                            handleAnswerTextChange(e.target.value)
                          }
                          placeholder="Other, Please mention"
                        />
                      </li>
                    ) : null}
                </ul>
                {currentQuestionIndex < totalQuestions - 1 && (
                  <button className="verify-next-button" onClick={handleNext}>
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {currentQuestionIndex === totalQuestions - 1 && !completed && (
          <button className="verify-next-button" onClick={handleCompleted}>Completed</button>
        )}
      </div>
    </div>
  );
}

export default VerifyQuestions;
