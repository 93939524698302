import React, { useState, useEffect, useRef } from "react";
import {  FaArrowRight, FaExpand } from "react-icons/fa";
import AudioPlayer from "react-h5-audio-player";
import { PiNotePencilFill } from "react-icons/pi";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer"
import {Link} from "react-router-dom"

import "react-h5-audio-player/lib/styles.css";

const fillInTheBlanksQuestions = {
  section1: [
    { id: 1, question: "The quick brown ___ jumps over the lazy dog." },
    { id: 2, question: "She sells ___ by the seashore." },
    {
      id: 3,
      question: "A journey of a thousand miles begins with a single ___ .",
    },
    { id: 4, question: "To be or not to ___ ." },
    { id: 5, question: "The pen is mightier than the ___ ." },
    { id: 6, question: "Actions speak louder than ___ ." },
  ],
  section2: [
    { id: 1, question: "The early bird catches the ___ ." },
    { id: 2, question: "Don't cry over spilled ___ ." },
    { id: 3, question: "Where there's smoke, there's ___ ." },
    { id: 4, question: "A penny for your ___ ." },
  ],
};

const multipleChoiceQuestions = {
  section1: [
    {
      id: 1,
      question: "What is the capital of France?",
      options: ["Berlin", "Madrid", "Paris", "Lisbon"],
    },
    {
      id: 2,
      question: "Which planet is known as the Red Planet?",
      options: ["Earth", "Mars", "Jupiter", "Venus"],
    },
  ],
  section2: [
    {
      id: 1,
      question: "Which fruit is known as the 'king of fruits'?",
      options: ["Apple", "Banana", "Mango", "Orange"],
    },
    {
      id: 2,
      question: "Which planet is closest to the Sun?",
      options: ["Earth", "Mars", "Mercury", "Venus"],
    },
  ],
};

const Listening = () => {
  const [timeRemaining, setTimeRemaining] = useState(2400); // 40 minutes for the quiz
  const [audioEnded, setAudioEnded] = useState(false);
  const [notepadVisible, setNotepadVisible] = useState(false);
  const audioRef = useRef(null);
  const [selectedSection, setSelectedSection] = useState("section1");
  const [answers, setAnswers] = useState({
    fillInTheBlanks: Array(fillInTheBlanksQuestions.section1.length).fill(""),
    multipleChoice: Array(multipleChoiceQuestions.section1.length).fill(""),
  });

  const sectionTimings = {
    section1: 0, // Start time in seconds
    section2: 1200, // Assume section 2 starts at 20 minutes (1200 seconds)
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleInputChange = (e, questionId, type) => {
    const newAnswers = { ...answers };
    if (type === "fillInTheBlank") {
      newAnswers.fillInTheBlanks[questionId - 1] = e.target.value;
    } else {
      newAnswers.multipleChoice[questionId - 1] = e.target.value;
    }
    setAnswers(newAnswers);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}m ${secs}s`;
  };

  const handleSectionChange = (e) => {
    const section = e.target.value;
    setSelectedSection(section);
    if (audioRef.current) {
      audioRef.current.audio.current.currentTime = sectionTimings[section];
    }
  };

  // const toggleNotepad = () => {
  //   setNotepadVisible(!notepadVisible);
  // };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  return (
    <>
     <div className="navbar-container">
        <Navbar />
      </div>
    <div className="audio-quiz-container">
      <div className="audio-toolbar">
        <div className="audio-time">{formatTime(timeRemaining)} remaining</div>
        <div className="audio-icons-top">
          {/* <FaRegStickyNote className="audio-icon" onClick={toggleNotepad} /> */}
          <FaExpand className="audio-icon" onClick={toggleFullScreen} />
        </div>
        <div className="audio-buttons-top">
          <button className="audio-button-preview">
            <PiNotePencilFill />
            Preview
          </button>
          <button className="audio-button-submit">
            Submit <FaArrowRight />
          </button>
          <Link to="/courses/coachingpage/Writing"><button className="audio-button-submit" >
            Writing<FaArrowRight />
          </button></Link>
        </div>
      </div>

      <div className="audio-player-container">
        <AudioPlayer
          ref={audioRef}
          src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" // Replace with your combined audio file URL
          onPlay={() => setAudioEnded(false)}
          onPause={() => setAudioEnded(false)}
        />
        <div className="controls-container">
          <div className="section-selector">
            <select
              id="sectionSelect"
              onChange={handleSectionChange}
              value={selectedSection}
            >
              <option value="section1">Section 1</option>
              <option value="section2">Section 2</option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div className="audio-questions">
          <h2>Fill in the Blanks</h2>
          <ol>
            {fillInTheBlanksQuestions[selectedSection].map((q, index) => (
              <li key={q.id} className="question-Blanks">
            
                <label><span>{index + 1}.</span>{" "}{q.question}</label>
              
                <input
                  type="text"
                  className="audio-questions-input"
                  value={answers.fillInTheBlanks[index]}
                  onChange={(e) => handleInputChange(e, q.id, "fillInTheBlank")}
                />
              </li>
            ))}
          </ol>
        </div>

        <div className="audio-questions">
          <h2>Multiple Choice Questions</h2>
          {multipleChoiceQuestions[selectedSection].map((q, index) => (
            <div key={q.id} className="question-choose">
              <div className="audio-question-info">
             
                <label className="audio-question-text"> {" "}  <span className="audio-question-number">
                  {index + fillInTheBlanksQuestions[selectedSection].length + 1}
                  .
                </span>{q.question}</label>
              </div>
              <div className="audio-options-container">
                {q.options.map((option, idx) => (
                  <div key={idx} className="audio-choose-radio">
                    <input
                      type="radio"
                      id={`q${q.id}o${idx}`}
                      name={`q${q.id}`}
                      value={option}
                      checked={answers.multipleChoice[index] === option}
                      onChange={(e) =>
                        handleInputChange(e, q.id, "multipleChoice")
                      }
                    />
                    <label htmlFor={`q${q.id}o${idx}`}>{option}</label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* {notepadVisible && (
        <div className={`notepad ${notepadVisible ? "visible" : ""}`}>
          <FaTimes className="close-icon" onClick={toggleNotepad} />
          <textarea placeholder="Write your notes here..."></textarea>
        </div>
      )} */}
    </div>
    <Footer/>
    </>
  );
};

export default Listening;
