import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import CoursesClasses from "./Courses.module.css";
import Footer from "../../Components/Footer/Footer";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import { Link } from "react-router-dom";

function Courses() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    GetCourses();
  }, []);

  const GetCourses = async () => {
    try {
      const res = await Services.GetCourses("GET", null);
      if (res.Status === 1) {
        setCourses(res.courses);
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const navigateToCoachingPage = async (ccId) => {
    try {
      const res = await Services.getCoursespagecontent("GET", null, ccId);
      if (res.Status === 1) {
        // Navigate to Testpage and pass course details as state
        navigate(`/courses/Coursepage?ccId=${ccId}`, { state: { courseDetails: res.blog } });
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className={CoursesClasses["About-Img-container"]}>
        <div className={CoursesClasses["About-container-img-text"]}>
          <div className={CoursesClasses["About-hero-text"]}>
            <h1 className={CoursesClasses["About-hero-text-h1"]}>Courses</h1>
            <p className={CoursesClasses["About-hero-text-p"]}>
              <Link to="/" className={CoursesClasses["About-Home-link"]}>
                Home
              </Link>
              /
              <span className={CoursesClasses["About-Home-link-span"]}>Courses</span>
            </p>
          </div>
        </div>
      </div>

      <div className={CoursesClasses["courses"]}>
        <div className={CoursesClasses["courses-container"]}>
          {courses.map((course) => (
            <div
              key={course.ccId}
              className={CoursesClasses["courses-item"]}
              onClick={() => navigateToCoachingPage(course.ccId)}
            >
              <img src={course.ccPhoto} alt="course_image" className={CoursesClasses["courses-img"]} />
              <h3 className={CoursesClasses["courses-title"]}>{course.ccName} Coaching</h3>
              <p className={CoursesClasses["courses-paragraph"]}>{course.ccDescription}</p>
            </div>
          ))}
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default Courses;
