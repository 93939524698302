import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home/Home";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import Courses from "./Pages/Courses/Courses";
import FreeResources from "./Pages/FreeResources/FreeResources";
import Coachingpage from "./Pages/Courses/Coachingpage";
import Blogs from "./Pages/Blogs/Blogs";
import ReadMore from "./Pages/Blogs/ReadMore";
import Colleges from "./Pages/Colleges/Colleges";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Login/Register";
import Verifyquestions from "./Pages/Login/verifyquestions";
import ScrollToTop from "./Components/utils/ScrollToTop";
import EnquiryModal from "./Pages/enquiryform/EnquiryModal";
import PartTime from "./Pages/PartTime/PartTime";
import Testimonial from "./Pages/Testimonial/Testimonial";
import OTPVerification from "./Pages/Login/OTPVerification";
import Searchpage from "./Pages/Home/Searchpage";
import OTPVerifyLogin from "./Pages/Login/OTPVerifyLogin";
import Ratemychance from "./Pages/Colleges/Ratemychance";
import Testpage from "./Pages/Courses/Testpage";
import Coursespage from "./Pages/Courses/Coursespage";
import Listening from "./Pages/Courses/Listening";
import Writing from "./Pages/Courses/Writing";
import Collegedetails from "./Pages/Colleges/Collegedetails";
import ResourceBlogDetails from "./Pages/Home/ResourceBlogDetails";
import Scholarships from "./Pages/Colleges/Scholarships";

export default function App() {
  return (
    <div className="App">
      <ToastContainer />
      <ScrollToTop />
      <EnquiryModal />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/Coursepage" element={<Coursespage />} />
        <Route path="/courses/coachingpage/testpage" element={<Testpage />} />
        <Route path="/courses/coachingpage/Listening" element={<Listening />} />
        <Route path="/courses/coachingpage/Writing" element={<Writing />} />

        <Route path="/colleges" element={<Colleges />} />
        <Route path="/colleges/collegedetails" element={<Collegedetails />} />
        <Route path="/colleges/Ratemychance" element={<Ratemychance />} />
        <Route path="/courses/coachingpage" element={<Coachingpage />} />

        <Route path="/freeresources" element={<FreeResources />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Parttime" element={<PartTime />} />
        <Route path="/Blogs/:id" element={<ReadMore />} />
        <Route path="/register" element={<Register />} />
        <Route path="/OTPVerification" element={<OTPVerification />} />
        <Route path="/OTPVerifyLogin" element={<OTPVerifyLogin />} />
        <Route path="/Verifyquestions" element={<Verifyquestions />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/Searchpage" element={<Searchpage />} />
        <Route path="/Scholarships" element={<Scholarships/>} />
        <Route path="/resources/:resourceId" element={<ResourceBlogDetails/>} />

      </Routes>
    </div>
  );
}
