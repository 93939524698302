import React, { useState, useEffect } from "react";
import { FaBarsStaggered } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import "./Navbar.css";
import Logo from "../../assets/logo.png";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login"); 
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <nav className="NavbarItems">
      <Link to="/">
        <h1 className="navbar-logo">
          <img src={Logo} alt="logo" width={80} />
        </h1>
      </Link>
      
      <div className="menu-icons" onClick={handleClick}>
        {clicked ? <FaTimes /> : <FaBarsStaggered />}
      </div>

      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <NavLink
              className={({ isActive }) => (isActive ? 'nav-links Active' : 'nav-links')}
              to={item.url}
              onClick={handleClick}
            >
              {item.title}
            </NavLink>
          </li>
        ))}
        {!isAuthenticated ? (
          <Link to="/login">
            <button className="Navbar-login">Login</button>
          </Link>
        ) : (
          <button className="Navbar-logout" onClick={handleLogout}>Logout</button>
        )}
      </ul>
      
    </nav>
  );
};

export default Navbar;