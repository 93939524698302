import React, { useEffect, useState } from 'react';
import { ToastError, ToastSuccess } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useNavigate } from 'react-router-dom';
import { useJwt } from "react-jwt";

function Webinar({ setDatapage, datapage, webfilterData, setWebfilterData }) {

  const token = { Authorization: `token ${localStorage.getItem("token")}` };


  const jwttoken = localStorage.getItem("token")


  const [webinars, setWebinars] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPageCount, setPageCount] = useState(0);
  const navigate = useNavigate();
  const { decodedToken, isExpired } = useJwt(jwttoken);
  const itemsPerPage = 5;



  useEffect(() => {
    getWebinars(activePage);
  }, [activePage]);

  const getWebinars = async (page) => {
    try {
      const res = await Services.Getwebinars("GET", null, itemsPerPage, page);
      if (res.Status === 1) {
        setWebfilterData(res.webinars);
        setDatapage(res.pagination.totalWebinarsCount)
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };



  const handleRegistration = async (webinarId) => {
    try {

      const isLoggedIn = true;

      if (decodedToken) {

        const res = await Services.RegisterforWebinar("POST", null, token, webinarId);
        if (res.Status === 1) {
          ToastSuccess("Successfully registered for the webinar.");
        } else {
          ToastError(res.Error);
        }
      } else {
        redirectToLoginPage();
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const redirectToLoginPage = () => {
    navigate("/login");
  };

  return (
    <div>
      <div className="webiner-tab-content">
        {webfilterData.map((webinar, index) => (
          <div className="webiner-content-card" key={index}>
            <div className="webiner-card">
              <h2>{webinar.webinarTitle}</h2>
              <p>{webinar.webinarDescription}</p>
              {webinar.webinarPhoto && <img src={webinar.webinarPhoto} alt="Webinar" />}
              <div className="webiner-date-time">
                <p>
                  <FaCalendarAlt /> {webinar.webinarDate}
                </p>
                <p>
                  <FaClock /> {webinar.webinarStartTime} - {webinar.webinarEndTime}
                </p>
              </div>
            </div>
            <button className="webiner-button" onClick={() => handleRegistration(webinar.webinarId)}>
              Register Now
            </button>
          </div>
        ))}
      </div>

      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={datapage}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>

    </div>
  );
}

export default Webinar;
