import React, { useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { MdComputer } from "react-icons/md";
import { GiWhiteBook } from "react-icons/gi";
import Coachingpage from "./Coachingpage";
import Testpage from "./Testpage";
import CoursesClasses from "./Courses.module.css";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

function Coursespage() {
  const [activeTab, setActiveTab] = useState("ebooks");
  const [params] = useSearchParams();
  const id = params.get("ccId");

  return (
    <>
      <Navbar />
      <div className={CoursesClasses["About-Img-container"]}>
        <div className={CoursesClasses["About-container-img-text"]}>
          <div className={CoursesClasses["About-hero-text"]}>
            <h1 className={CoursesClasses["About-hero-text-h1"]}>Courses</h1>
            <p className={CoursesClasses["About-hero-text-p"]}>
              <Link to="/" className={CoursesClasses["About-Home-link"]}>
                Home
              </Link>
              /
              <span className={CoursesClasses["About-Home-link-span"]}>Courses</span>
            </p>
          </div>
        </div>
      </div>

      <div className={CoursesClasses["Free-Resources"]}>
        <div className={CoursesClasses["Free-Resources-heading"]}>
          <div
            className={`${CoursesClasses["free-Resource-header"]} ${
              activeTab === "ebooks" ? CoursesClasses["active"] : ""
            }`}
            onClick={() => setActiveTab("ebooks")}
          >
            <GiWhiteBook className={CoursesClasses["free-resources-icon"]} />
            Write A Test
          </div>
          <div
            className={`${CoursesClasses["free-Resource-header"]} ${
              activeTab === "webinar" ? CoursesClasses["active"] : ""
            }`}
            onClick={() => setActiveTab("webinar")}
          >
            <MdComputer className={CoursesClasses["free-resources-icon"]} />
            Coaching
          </div>
        </div>
        <div className={CoursesClasses["free-Resource-content"]}>
          {activeTab === "ebooks" && (
            <div className={CoursesClasses["content"]}>
              <Testpage id={id} />
            </div>
          )}
          {activeTab === "webinar" && (
            <div className={CoursesClasses["content"]}>
              <Coachingpage id={id} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Coursespage;
