import React from "react";
import { useForm } from "react-hook-form";
import RegisterImg from "../../assets/register.png";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { ToastSuccess, ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import "./Login.css";

function Register() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const { name, email, mobileNumber } = data;
      const res = await Services.Register(
        "POST",
        JSON.stringify({ name, email, mobileNumber })
      );
      if (res.Status === 1) {
        ToastSuccess("Registration successful.");
        localStorage.setItem("mobile", mobileNumber);
        navigate("/otpverification");
      } else {
        ToastError(res.
          Message
          );
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="Login">
      <div className="login-img-container">
        <img src={RegisterImg} alt="img" className="login-img" />
      </div>

      <div className="login-form">
        <h1>VeTEACH Overseas</h1>
        <p>
          Guidance Platform
          <br />
          for Admissions, GRE, IELTS, TOEFL, DUOLINGO, PTE, NAATI, GMAT
        </p>
        <h2>Register for the Online Seminar!</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
         

          <div className="register-form-details">
  <label className="label-form">Name</label>
  <input
    className="label-form-input"
    type="text"
    {...register("name", {
      required: "Name is required",
      pattern: {
        value: /^[A-Za-z\s]+$/,
        message: "Name must contain only alphabets and spaces"
      }
    })}
  />
  {errors.name && <p className="error-message">{errors.name.message}</p>}
</div>


          <div className="register-form-details">
            <label className="label-form">Email</label>
            <input
              className="label-form-input"
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address"
                }
              })}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}
          </div>

          <div className="register-form-details">
            <label className="label-form">Phone Number</label>
            <input
              className="label-form-input"
              type="tel"
              {...register("mobileNumber", {
                required: "Phone number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Phone number must be 10 digits"
                }
              })}
            />
            {errors.mobileNumber && <p className="error-message">{errors.mobileNumber.message}</p>}
          </div>

          <div className="register-button-container">
            <button type="submit" className="Navbar-login">
              Register <MdOutlineArrowRightAlt />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
