// Authorization: `token ${token}`,
const httpcall = (url, method, body, token) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: method,
    body: body,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      ...token,
    },
  }).then((response) => response.json());
};

//--------SignUp--------//

const blobFile = (url, method, body, token) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
    method: method,
    body: body,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      ...token,
    },
  })
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));
};

const Register = (method, body) => {
  return httpcall("auth/sign-up", method, body);
};
const VerifyOTP = (method, body) => {
  return httpcall("auth/verify-otp", method, body);
};
const LoginSendOTP = (method, body) => {
  return httpcall("auth/send-otp", method, body);
};
const LoginVerifyOTP = (method, body) => {
  return httpcall("auth/login", method, body);
};

const GetProfileQuestions = (method, body, token) => {
  return httpcall("users/profile/form", method, body, token);
};

const SaveProfileAnswers = (method, body, token) => {
  return httpcall("users/profile/save", method, body, token);
};
/*search*/
const Getstatessearch = (method, body,search) => {
  return httpcall(`content/search-states?search=${search}`, method, body);
};

const DownloadSearchResults = (method, body) => {
  return blobFile(`content/search-results/download`, method, body);
};

/*Home*/

const GetHomePageColleges = (method, body, size) => {
  return httpcall(`content/colleges?size=${size}`, method, body);
};

/*About*/
const GetAboutUs = (method, body) => {
  return httpcall("content/about-us", method, body);
};


/*courses*/

const FreeImigation = (method, body) => {
  return httpcall("content/free-assessment", method, body);
};

const  getCoursespagecontent = (method, body,ccId) => {
  return httpcall(`content/course-blog/${ccId}`, method, body);
};

/*Get Testimonials*/
const GetTestimonials = (method, body, size, page) => {
  return httpcall(
    `content/testimonials?size=${size}&page=${page}`,
    method,
    body
  );
};

/*colleges*/
const GetAllColleges = (method, body, size, page) => {
  return httpcall(
    `content/colleges/all?size=${size}&page=${page}`,
    method,
    body
  );
};

const Getcollegecourses = (method, body, token, collegeId) => {
  return httpcall(`users/rate-my-chance/${collegeId}`, method, body, token);
};

const ratemychance = (method, body, token) => {
  return httpcall(`users/rate-my-chance`, method, body, token);
};
const GetBrochureLink = (method, body, token, collegeId) => {
  return httpcall(
    `users/university-brochure/${collegeId}`,
    method,
    body,
    token
  );
};

/*free resources*/
const GetEbooks = (method, body, token, size, page) => {
  return httpcall(
    `content/ebooks?size=${size}&page=${page}`,
    method,
    body,
    token
  );
};

const Getwebinars = (method, body, size, page) => {
  return httpcall(`content/webinars?size=${size}&page=${page}`, method, body);
};

const RegisterforWebinar = (method, body, token, webinarId) => {
  return httpcall(`users/webinars/register/${webinarId}`, method, body, token);
};

const Getvideos = (method, body, size, page) => {
  return httpcall(`content/videos?size=${size}&page=${page}`, method, body);
};

/*blogs*/

const GetBlogs = (method, body, size, page) => {
  return httpcall(`content/blogs?size=${size}&page=${page}`, method, body);
};

const HomeBlogs = (method, body) => {
  return httpcall(`content/blogs`, method, body);
};

const SaveReplyBlogs = (method, body) => {
  return httpcall(`content/save-reply`, method, body);
};

const GetBlogbyID = (method, body,metaUrl) => {
  return httpcall(`content/blogs/url/${metaUrl}`, method, body);
};


const GetRecentBlogs = (method, body,int) => {
  return httpcall(`content/blogs/latest/?size=${int}`, method, body);
};


/*part time*/
const GetUploadURL = (method, body, filename) => {
  return httpcall(`content/get-upload-url?filename=${filename}`, method, body);
};

const SubmitApplicationForm = (method, body) => {
  return httpcall("content/submit-application", method, body);
};

/*testimonials*/
const Gettestimonials = (method, body, size, page) => {
  return httpcall(
    `content/testimonials?size=${size}&page=${page}`,
    method,
    body
  );
};

const GetCourseDetails = (method, body, courseId) => {
  return httpcall(`content/courses/${courseId}`, method, body);
};

/*Contact*/
const ContactPage = (method, body) => {
  return httpcall("content/contact-us", method, body);
};

/*Resources*/
const GetResources = (method, body) => {
  return httpcall("content/resources", method, body);
};

const GetResourcebyID = (method, body,resourceId) => {
  return httpcall(`content/resources/${resourceId}`, method, body);
};

const GetResourcebyIDMenuItemID  = (method, body,resourceId,menuItemId) => {
  return httpcall(`content/resources/${resourceId}/${menuItemId}`, method, body);
};

/*Save Enquiry Form*/
const SaveEnquiryForm = (method, body) => {
  return httpcall("content/enquiry", method, body);
};

const GetBanners = (method, body) => {
  return httpcall("content/banners", method, body);
};

const Searchbutton = (method, body, token) => {
  return httpcall("content/search", method, body, token);
};

const comparecoursesdownload = (method, body) => {
  return blobFile("content/compare-courses/download", method, body);
};

const comparecourses = (method, body) => {
  return httpcall("content/compare-courses", method, body);
};
const Shortlistcourses = (method, body, token) => {
  return httpcall("users/shortlist", method, body, token);
};

const FilterColleges = (method, body, size, page) => {
  return httpcall(
    `content/colleges/all?size=${size}&page=${page}`,
    method,
    body
  );
};

const GetCourses = (method, body) => {
  return httpcall(`content/coaching-courses`, method, body);
};

// Categories in ebooks

const GetCategories = (method, body) => {
  return httpcall(`content/ebooks/categories`, method, body);
};

const CategoryByID = (method, body, number, count, Id) => {
  return httpcall(
    `content/ebooks?size=${count}&page=${number}&categoryId=${Id}`,
    method,
    body
  );
};
// webiner categories

const WebnirCategories = (method, body) => {
  return httpcall(`content/webinars/categories`, method, body);
};

const WebnirCategoryByID = (method, body, number, count, Id) => {
  return httpcall(
    `content/webinars?size=${count}&page=${number}&categoryId=${Id}`,
    method,
    body
  );
};

// Video categories

const videoCategories = (method, body) => {
  return httpcall(`content/videos/categories`, method, body);
};

const VideoCategoryByID = (method, body, number, count, Id) => {
  return httpcall(
    `content/videos?size=${count}&page=${number}&categoryId=${Id}`,
    method,
    body
  );
};

// Download Ebook
const ebookDownload = (method, body, token, id) => {
  return httpcall(`users/ebook/${id}`, method, body, token);
};

// Coaching Programs

const Coachingprograms = (method, body, id) => {
  return httpcall(`content/coaching-courses/${id}/exams`, method, body);
};

// Resourse Blogs
const ResourseBlogs = (method, body) => {
  return httpcall(`content/resources`, method, body);
};


// Resourse Blogs
const SearchData = (method) => {
  return httpcall(`content/dropdown-data`, method);
};

export const Services = {
  Register,
  VerifyOTP,
  LoginSendOTP,
  LoginVerifyOTP,
  GetProfileQuestions,
  SaveProfileAnswers,
  Getstatessearch,
  
  GetHomePageColleges,
  Gettestimonials,
  GetAboutUs,
  GetAllColleges,
  ratemychance,
  Getcollegecourses,
  GetEbooks,
  Getwebinars,
  RegisterforWebinar,
  Getvideos,
  GetBlogs,
  HomeBlogs,
  SaveReplyBlogs,
  GetBlogbyID,
  GetRecentBlogs,
  GetBanners,
  GetCourses,
  getCoursespagecontent,
  Searchbutton,

  SubmitApplicationForm,
  GetUploadURL,
  DownloadSearchResults,

  ContactPage,
  SaveEnquiryForm,
  GetTestimonials,
  GetCourseDetails,
  comparecourses,
  comparecoursesdownload,
  Shortlistcourses,
  GetBrochureLink,
  FilterColleges,
  GetCategories,
  CategoryByID,
  WebnirCategories,
  WebnirCategoryByID,
  videoCategories,
  VideoCategoryByID,
  ebookDownload,
  Coachingprograms,
  ResourseBlogs,
  FreeImigation,

  GetResources,
  GetResourcebyID,
  GetResourcebyIDMenuItemID,
  SearchData 
};
