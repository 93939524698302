import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import HomeClasses from "./Home.module.css";
import { Services } from '../../Services'; 
import { ToastError } from '../../Components/utils/ToastMessages'; 

function Review() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [allTestimonials, setAllTestimonials] = useState([]);
  const navigate = useNavigate();

  const Gettestimonials = async () => {
    try {
      const res = await Services.Gettestimonials("GET", null, 100, 1); 
      if (res.Status === 1) {
        setData(res.testimonials.slice(0, 8)); // Show only the first 8 testimonials
        setAllTestimonials(res.testimonials); // Save all testimonials
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.error(err);
      setError(true);
      ToastError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Gettestimonials();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading testimonials</div>;

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: true,
    // autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={HomeClasses["review-container"]}>
      <div className={HomeClasses["testimonials-header"]}>
        <h1>Testimonials</h1>
        <button
          className={HomeClasses["view-all-button"]}
          onClick={() => navigate("/testimonial", { state: { testimonials: allTestimonials } })}
        >
          View All
        </button>
      </div>

      <Slider {...settings}>
        {data.map((review, index) => (
          <div key={index} className={HomeClasses["review-item"]}>
            <div className={HomeClasses["review-img-body"]}>
              <img
                src={review.photo}
                alt={review.testimonialName}
                className={HomeClasses["review-img"]}
              />
            </div>
            <div>
              <div className={HomeClasses["review-name-container"]}>
                <span className={HomeClasses["review-name"]}>{review.testimonialName}</span>
                <span className={HomeClasses["review-rating"]}>
                  <FaStar className={HomeClasses["review-icon"]} /> {review.testimonialRating}
                </span>
              </div>
              <p className={HomeClasses["review-para"]}>{review.testimonialText}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Review;
