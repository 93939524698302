import React, { useEffect, useState } from "react";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./Colleges.css";
import Pagination from "react-js-pagination";
import Filter from "../../Pages/Colleges/Filter";
import CollegeList from "./CollegeList";
import { Link } from "react-router-dom";

const Colleges = () => {
  const [activePage, setActivePage] = useState(1);
  const [colleges, setColleges] = useState([]);
  const [totalCollegesCount, setTotalCollegesCount] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const itemsPerPage = 4;

  useEffect(() => {
    GetAllColleges(activePage);
  }, [activePage]);

  const transformFilterData = (filters) => {
    return [
      {
        heading: "Specialization",
        filterKey: "specializations",
        options: filters.specializationsFilter.map(s => ({ label: s.label, value: s.value, count: s.count })),
      },
      {
        heading: "Exams Accepted",
        filterKey: "exams",
        options: filters.examsAcceptedFilter.map(e => ({ label: e.label, value: e.value, count: e.count })),
      },
      {
        heading: "1st Year Fees",
        filterKey: "firstYearFees",
        options: filters.feesFilter.map(f => ({ label: f.label, value: f.value, count: f.count })),
      },
      {
        heading: "Country",
        filterKey: "countries",
        options: filters.countriesFilter.map(c => ({ label: c.label, value: c.value, count: c.count })),
      },
      {
        heading: "Intake",
        filterKey: "intakes",
        options: filters.intakesFilter.map(i => ({ label: i.label, value: i.value, count: i.count })),
      },
      {
        heading: "Course Duration",
        filterKey: "courseDuration",
        options: filters.courseDurationsFilter.map(cd => ({ label: cd.label, value: cd.value, count: cd.count })),
      },
      {
        heading: "Work Experience",
        filterKey: "workExperience",
        options: filters.workExperienceFilter.map(we => ({ label: we.label, value: we.value, count: we.count })),
      },
      {
        heading: "More Options",
        filterKey: "moreOptions",
        options: [
          { label: "Offers Scholarship", value: "offersScholarship", count: filters.offersScholarshipFilter[0]?.count },
          { label: "Publicly Funded", value: "publiclyFunded", count: filters.publiclyFundedFilter[0]?.count },
          { label: "With Accommodation", value: "withAccommodation", count: filters.withAccommodationFilter[0]?.count },
          { label: "3 Year Degree Acceptable", value: "threeYearDegreeAcceptable", count: filters.threeYearDegreeAcceptableFilter[0]?.count },
        ],
      },
    ];
  };

  const GetAllColleges = async (page, filters = {}) => {
    try {
      const res = await Services.FilterColleges("POST", JSON.stringify(filters), itemsPerPage, page);
      if (res.Status === 1) {
        setColleges(res.colleges);
        setTotalCollegesCount(res.pagination.totalCollegesCount);
        setFilterData(transformFilterData(res.filters));
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleFilterChange = (filters) => {
    GetAllColleges(1, filters);
  };


  return (
    <div>
      <Navbar />
   

      <div className="About-Img-container">
        <div className="About-container-img-text">
          <div className="About-hero-text">
            <h1 className="About-hero-text-h1">Colleges</h1>
            <p className="About-hero-text-p">
              <Link to="/" className="About-Home-link">
                Home
              </Link>
              /
              <span className="About-Home-link-span">Colleges</span>
            </p>
          </div>
        </div>
      </div>

      <div className="college-container">
        <div className="college-one-container">
          <h2>MS in Abroad for Indian Students - Universities, Colleges, Fees 2024, Deadlines</h2>
          <p>Find information related to Cutoffs, Placements, Courses, Fees, Admissions, Rankings, Eligibility and Reviews for MS in Abroad for Indian Students - Universities, Colleges, Fees 2024, Deadlines</p>
        </div>

        <div className="college-two-container">
          <div className="college-two-list">
            <CollegeList colleges={colleges} />
          </div>
          <div className="college-two-filter">
            <Filter filterData={filterData} onFilterChange={handleFilterChange} />
          </div>
        </div>
      </div>

      <div className="pagination-container">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalCollegesCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>

      <Footer />
    </div>
  );
};

export default Colleges;
