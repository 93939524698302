import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { Link } from "react-router-dom";
import HomeClasses from "./Home.module.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import about1 from "../../assets/about1.png";
import about2 from "../../assets/about2.png";
import Exam1 from "../../assets/exam1.png";
import Exam2 from "../../assets/exam2.png";
import Exam3 from "../../assets/exam3.png";
import Exam4 from "../../assets/exam4.png";
import Exam5 from "../../assets/exam5.png";
import Exam6 from "../../assets/exam6.png";
import Exam7 from "../../assets/exam7.png";
import Review from "./Review";
import RecentBlogs from "./RecentBlogs";
import Footer from "../../Components/Footer/Footer";
import Searchbar from "./Searchbar";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResourceBlog from "./ResourceBlog";



const Examtypes = [
  {
    name: "GRE",
    image: Exam1,
  },
  {
    name: "IELTS",
    image: Exam2,
  },
  {
    name: "TOEFL",
    image: Exam3,
  },
  {
    name: "GMAT",
    image: Exam4,
  },
  {
    name: "DUOLINGO",
    image: Exam5,
  },
  {
    name: "PTE",
    image: Exam6,
  },
  {
    name: "NAATI",
    image: Exam7,
  },
];

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

function Home() {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    GetBanners();
  }, []);

  const GetBanners = async () => {
    try {
      const res = await Services.GetBanners("GET", null, 3);
      if (res.Status === 1) {
        setBanners(res.banners);
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    GetHomePageColleges();
  }, []);

  const GetHomePageColleges = async () => {
    try {
      const res = await Services.GetHomePageColleges("GET", null, 3);
      if (res.Status === 1) {
        setColleges(res.colleges);
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  const UniversityCard = ({ collegeName, collegeLocation, collegePhoto }) => {
    return (
      <div className={HomeClasses["college-card"]}>
        <img
          src={collegePhoto}
          alt={collegeName}
          className={HomeClasses["college-card-image"]}
        />
        <div className={HomeClasses["college-card-content"]}>
          <h3 className={HomeClasses["college-card-title"]}>{collegeName}</h3>
          <p className={HomeClasses["college-card-location"]}>
            <i className={HomeClasses["college-location-icon"]}></i>
            {collegeLocation}
          </p>
        </div>
        <i className={HomeClasses["college-bookmark-icon"]}></i>
      </div>
    );
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    dots: false,
  };


  return (
    <div className={HomeClasses["Veteach-home"]}>
      <Navbar />

      <Slider {...sliderSettings} className={HomeClasses["slider"]}>
        {banners.map((banner, index) => (
          <div key={index} className={HomeClasses["home_slider_wrapper"]}>
            <img src={banner.bannerImageUrl} alt={`Banner ${index + 1}`} />
            <div className={HomeClasses["Home-Img-container"]}>
              <div className={HomeClasses["Home-hero-text"]}>
              <h1 className={HomeClasses["hero-text-h1"]}>
              {truncateText(banner.bannerTitle,45)}
            </h1>
            <p className={HomeClasses["hero-text-p"]}>
              {truncateText(banner.bannerDescription, 60)}
            </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <Searchbar
        setShowAdvancedSearch={setShowAdvancedSearch}
        showAdvancedSearch={showAdvancedSearch}
      />

      {showAdvancedSearch && <div style={{ height: "50px" }}></div>}

      <div className={HomeClasses["college-list"]}>
        <h1 className={HomeClasses["college-list-heading"]}>
          List of colleges
        </h1>
        <div className={HomeClasses["college-container"]}>
          {colleges.map((college, index) => (
            <UniversityCard
              key={index}
              collegeName={college.collegeName}
              collegeLocation={college.collegeLocation}
              collegePhoto={college.collegePhoto}
            />
          ))}
        </div>
        <div className={HomeClasses["view-all-button-container"]}>
          <Link to="/colleges">
            <button className={HomeClasses["view-all-button"]}>
              View All Colleges
            </button>
          </Link>
        </div>
      </div>

      <div className={HomeClasses["Home-about"]}>
        <h1 className={HomeClasses["Home_course_h1"]}>About</h1>
        <div className={HomeClasses["Home-about-box"]}>
          <div className={HomeClasses["Home-about-Container1"]}>
            <h5 className={HomeClasses["Home-about-small-heading"]}>
              Why Choose Us
            </h5>
            <h3 className={HomeClasses["Home-about-large-heading"]}>
              Lorem Ipsum is simply dummy text <br></br> of the printing
            </h3>
            <p className={HomeClasses["Home-about-large-paragraph"]}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.{" "}
            </p>
          </div>
          <div className={HomeClasses["Home-about-Container2"]}>
            <img
              src={about1}
              alt="about1"
              className={HomeClasses["Home-about1-img"]}
            ></img>
            <img
              src={about2}
              alt="about2"
              className={HomeClasses["Home-about2-img"]}
            ></img>
          </div>
        </div>
      </div>

      <div className={HomeClasses["Home-course-container"]}>
        <h1 className={HomeClasses["Home_course_h1"]}>Courses</h1>
        <div className={HomeClasses["exam-container"]}>
          {Examtypes.map((Exam, index) => (
            <div key={index} className={HomeClasses["exam-item"]}>
              <img
                src={Exam.image}
                alt={Exam.name}
                className={HomeClasses["exam-image"]}
              />
              <h4 className={HomeClasses["exam-name"]}>{Exam.name}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className={HomeClasses["Exam-assessment"]}>
        <div className={HomeClasses["Exam-assessment-container1"]}>
          <h3 className={HomeClasses["Exam-assessment-heading-large"]}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </h3>
          <h6 className={HomeClasses["Exam-assessment-heading-small"]}>
            Lorem Ipsum has been the industry's{" "}
          </h6>
        </div>
        <div className={HomeClasses["Exam-assessment-container2"]}>
          <button className={HomeClasses["Exam-assessment-button"]}>
            Get Free Assessment
            <IoIosArrowRoundForward
              className={HomeClasses["Exam-assessment-button-icon"]}
            />
          </button>
        </div>
      </div>

      <Review />
      <ResourceBlog />
      <RecentBlogs/>
      <Footer />
    </div>
  );
}

export default Home;
