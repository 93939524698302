import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Services } from "../../Services";
import { useLocation, useParams } from "react-router-dom";
import HomeClasses from "./Home.module.css";
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";

const ResourceBlogDetails = () => {
  const { resourceId } = useParams(); // Extracting resourceId from URL params
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null); // State for active tab
  const [resourceDetails, setResourceDetails] = useState(
    location.state?.resource || null
  ); // State for resource details
  const [menuItems, setMenuItems] = useState([]); // State for menu items
  const [openFaq, setOpenFaq] = useState(null); // State for open FAQ index
  const [cache, setCache] = useState({}); // Cache for storing fetched data
  const [isDetailsFetched, setIsDetailsFetched] = useState(false); // State to track if resource details have been fetched

  // Function to fetch menu item details by ID
  const GetResourcebyIDMenuItemID = async (menuItemId) => {
    // Check if the data for this menuItemId is already in cache
    if (cache[menuItemId]) {
      setResourceDetails((prevState) => ({
        ...prevState,
        menuItemInfo: cache[menuItemId].resource.menuItemInfo,
      }));
      return;
    }

    try {
      const res = await Services.GetResourcebyIDMenuItemID(
        "GET",
        null,
        resourceId,
        menuItemId
      );
      if (res.Status === 1) {
        // Update state and cache
        setCache((prevCache) => ({
          ...prevCache,
          [menuItemId]: {
            content: res.content,
            resource: res.resource,
          },
        }));
        setResourceDetails((prevState) => ({
          ...prevState,
          menuItemInfo: res.resource.menuItemInfo,
        }));
      } else {
        console.error(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Error fetching data");
    }
  };

  // Effect to fetch resource details on component mount or when resourceId changes
  useEffect(() => {
    if (!isDetailsFetched) {
      const fetchResourceDetails = async () => {
        try {
          const res = await Services.GetResourcebyID("GET", null, resourceId);
          if (res.Status === 1) {
            setResourceDetails(res.resource);
            setMenuItems(res.resource.menuList || []);
            if (res.resource.menuList.length > 0) {
              setActiveTab(res.resource.menuList[0].menuItemId);
            }
            setIsDetailsFetched(true); // Set to true after successful fetch
          } else {
            console.error(res.Message);
          }
        } catch (err) {
          console.log(err);
          alert("Error fetching data");
        }
      };
      fetchResourceDetails();
    }
  }, [resourceId, isDetailsFetched]);

  // Effect to fetch menu item details when activeTab changes
  useEffect(() => {
    if (activeTab !== null) {
      console.log(activeTab,"Active Tab")
      GetResourcebyIDMenuItemID(activeTab);
    }
  }, [activeTab]);

  // Function to toggle FAQ visibility
  const toggleFAQ = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  // Render JSX
  return (
    <div>
      <Navbar />

      {/* Hero section */}
      <div className="Blogs-Img-container">
        <div className="Main-container-img-text">
          <div className="Blog-hero-text">
            <h1 className="hero-text-h1">Resources Blogs</h1>
            <p className="hero-text-p">Home/Resources Blogs</p>
          </div>
        </div>
      </div>

      {/* Main content container */}
      <div className={HomeClasses["admissions-guide-container"]}>
        {resourceDetails && (
          <>
            {/* Tabs section */}
            <div className={HomeClasses["ResourceBlog-main"]}>
              <div className={HomeClasses["ResourceBlog-tabs"]}>
                <ul>
                  {menuItems.map((item) => (
                    <li
                      key={item.menuItemId}
                      className={
                        activeTab === item.menuItemId ? HomeClasses.active : ""
                      }
                      onClick={() => setActiveTab(item.menuItemId)}
                    >
                      {item.menuItemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Main content section */}
            <div className={HomeClasses["ResourceBlog-main-one"]}>
              <div className={HomeClasses["ResourceBlog-main-one-img"]}>
                <img
                  src={resourceDetails.menuItemInfo?.menuItemPhoto}
                  alt="Resource"
                  className={HomeClasses["ResourceBlogs-image"]}
                />
              </div>
              <div className={HomeClasses["ResourceBlog-main-one-content"]}>
                <h3>{resourceDetails.menuItemInfo?.menuItemHeading}</h3>
                <p>{resourceDetails.menuItemInfo?.menuItemBody}</p>
              </div>
            </div>

            {/* Additional sections */}
            <div className={HomeClasses["ResourceBlog-main-two-content"]}>
              {resourceDetails.menuItemInfo?.sections?.length > 0 ? (
                resourceDetails.menuItemInfo.sections.map((section, index) => (
                  <div
                    key={index}
                    className={HomeClasses["ResourceBlog-main-two-content"]}
                  >
                    <h3>{section.sectionHeading}</h3>
                    <p>{section.sectionBody}</p>
                    {section.sectionPhoto && (
                      <img
                        src={section.sectionPhoto}
                        alt={`Section ${index}`}
                        className={HomeClasses["Resourcesblog-section-image"]}
                      />
                    )}
                  </div>
                ))
              ) : (
                <p>No sections available.</p>
              )}
            </div>

            {/* FAQs section */}
            <div className={HomeClasses["faq-container"]}>
              <h2 className={HomeClasses["faq-container-heading"]}>FAQs</h2>
              {resourceDetails.menuItemInfo?.faqs?.map((faq, index) => (
                <div key={index} className={HomeClasses["faq-item"]}>
                  <div
                    className={HomeClasses["faq-question"]}
                    onClick={() => toggleFAQ(index)}
                  >
                    {faq.menuFaqQuestion}
                    <span className={HomeClasses["faq-arrow"]}>
                      {openFaq === index ? (
                        <IoIosArrowDropupCircle
                          className={HomeClasses["faq-question-arrow-icon"]}
                        />
                      ) : (
                        <IoIosArrowDropdownCircle
                          className={HomeClasses["faq-question-arrow-icon"]}
                        />
                      )}
                    </span>
                  </div>
                  <div
                    className={`${HomeClasses["faq-answer"]} ${
                      openFaq === index ? HomeClasses.open : ""
                    }`}
                  >
                    {faq.menuFaqAnswer}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ResourceBlogDetails;
