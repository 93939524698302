import React, { useState } from "react";
import "./EnquiryModal.css";
import { RxCross2 } from "react-icons/rx";
import { ToastSuccess, ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import { useNavigate } from "react-router-dom";
import getintouch from "../../assets/getintouch.svg";

const EnquiryModal = () => {
  const [showEnquiryModal, setModal] = useState(false);
  const navigate = useNavigate(); 
  const [dataToSend, setData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    detail: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await Services.SaveEnquiryForm(
        "POST",
        JSON.stringify(dataToSend)
      );
      if (res.Status === 1) {
        ToastSuccess("Message submitted successfully.");
        setData({
          name: "",
          email: "",
          phone: "",
          country: "",
          city: "",
          detail: ""
        });
        navigate("/"); 
      } else {
        ToastError(res.Error);
      }
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  return (
    <div>
      {showEnquiryModal && (
        <div className="enquiryModal-container">
          <div className="enquiryModal-image">
            <img src={getintouch} alt="Enquiry" />
          </div>
          <div className="enquiryModal-form">
            <div className="enquiryModal-header">
              <div className="enquiry-details-heading">
                Enquiry Details
              </div>
              <div
                className="enquiry-details-heading-icon"
                onClick={() => {
                  setModal(false);
                }}
              >
                <RxCross2 className="enquiry-icon" />
              </div>
            </div>
            <input
              onChange={(e) => {
                setData({ ...dataToSend, name: e.target.value });
              }}
              className="enquiry-details-input"
              placeholder="Name"
            />
            <input
              onChange={(e) => {
                setData({ ...dataToSend, email: e.target.value });
              }}
              className="enquiry-details-input"
              placeholder="Email"
            />
            <input
              onChange={(e) => {
                setData({ ...dataToSend, phone: e.target.value });
              }}
              className="enquiry-details-input"
              placeholder="Phone"
            />
            <input
              onChange={(e) => {
                setData({ ...dataToSend, country: e.target.value });
              }}
              className="enquiry-details-input"
              placeholder="Country"
            />
            <input
              onChange={(e) => {
                setData({ ...dataToSend, city: e.target.value });
              }}
              className="enquiry-details-input"
              placeholder="City"
            />
            <input
              onChange={(e) => {
                setData({ ...dataToSend, detail: e.target.value });
              }}
              className="enquiry-details-input enquiry-details-textarea"
              placeholder="Please put your enquiry details here"
            />
            <div
              onClick={(e) => {
                handleSubmit(e);
              }}
              className="enquiry-details-input-button"
            >
              Submit
            </div>
          </div>
        </div>
      )}

      {!showEnquiryModal && (
        <div
          onClick={() => {
            setModal(true);
          }}
          style={{
            textAlign: "center",
            width: 125,
            padding: 10,
            backgroundColor: "#072f5f",
            color: "white",
            top: 280,
            zIndex: 100,
            right: -40,
            position: "fixed",
            transform: "rotate(-90deg)",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            whiteSpace: "nowrap",
          }}
        >
          GET IN TOUCH
        </div>
      )}
    </div>
  );
};

export default EnquiryModal;
