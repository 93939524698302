import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastError } from "../../Components/utils/ToastMessages";
import { Services } from "../../Services";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Select from "react-select";
import "./Colleges.css";
import Modal from "react-modal";
import {countries,educationBoards} from "./Data"
import { useSearchParams } from "react-router-dom";

function RateMyChance() {
  const [searchparams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [consentGiven, setConsentGiven] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [passingYears, setPassingYears] = useState([]);
  const [studyYears, setStudyYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = { Authorization: `token ${localStorage.getItem("token")}` };

  const handleCheckboxChange = () => {
    setConsentGiven(!consentGiven);
  };

  const onSubmit = async (data) => {
    try {
      const requestBody = {
        courseId: data.courseSelection.value,
        studyYear: data.planToStudy.value,
        countryId: data.preferredCountry.map((country) => country.value),
        passingYear: data.passingYear.value,
        twelfthBoard: data.twelfthBoard.value,
        twelfthPercentage: parseFloat(data.twelfthPercentage),
        givenAbroadExam: data.givenAbroadExam.label,
      };

      const res = await Services.ratemychance(
        "POST",
        JSON.stringify(requestBody),
        token
      );

      if (res.Status === 1) {
        setModalIsOpen(true);
      } else {
        ToastError(res.Error);
      }
    } catch (error) {
      ToastError("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    Getcollegecourses(searchparams.get("CollegeId"));
  }, []);

  const Getcollegecourses = async (collegeId) => {
    try {
      const res = await Services.Getcollegecourses(
        "GET",
        null,
        token,
        collegeId
      );
      if (res.Status === 1) {
        const courseOptions = res.courses.map((course) => ({
          value: course.value,
          label: course.label,
        }));
        setCourses(courseOptions);
        setPassingYears(res.passingYears);
        setStudyYears(res.studyYears);
      } 
    } catch (err) {
      ToastError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div className="navbar-container">
        <Navbar />
      </div>
      <div style={{ padding: "20px" }}>
        <div className="rate-mychance-main-Container">
          <h1 className="ratemy-chance-heading">Rate My Chance</h1>
          <div className="rate-my-chance-Container1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="rate-my-chance-section1">
                <h3>1. Future Education Preference</h3>
                <p>
                  This will help us show you information related to your course
                  & country of interest.
                </p>
                <div className="form-item">
                  <Controller
                    name="courseSelection"
                    control={control}
                    rules={{ required: "Course selection is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={courses}
                        className="select-input-field"
                        placeholder={
                          loading
                            ? "Loading courses..."
                            : "Select Courses at this university"
                        }
                        isDisabled={loading}
                      />
                    )}
                  />
                  {errors.courseSelection && (
                    <div className="error">
                      {errors.courseSelection.message}
                    </div>
                  )}
                </div>
                <div className="form-item">
                  <Controller
                    name="planToStudy"
                    control={control}
                    rules={{ required: "Plan to study selection is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={studyYears}
                        className="select-input-field"
                        placeholder="When do you plan to start your studies?"
                      />
                    )}
                  />
                  {errors.planToStudy && (
                    <div className="error">{errors.planToStudy.message}</div>
                  )}
                </div>
                <div className="form-item">
                  <Controller
                    name="preferredCountry"
                    control={control}
                    rules={{
                      required: "Preferred country selection is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={countries.map((country, index) => ({
                          value: index + 1,
                          label: country,
                        }))}
                        className="select-input-field"
                        placeholder="Preferred country?"
                        isMulti
                      />
                    )}
                  />
                  {errors.preferredCountry && (
                    <div className="error">
                      {errors.preferredCountry.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="rate-my-chance-section1">
                <h3>2. Current Education Details</h3>
                <p>
                  This will help us show you information related to your course
                  & country of interest.
                </p>
                <div className="form-item">
                  <Controller
                    name="passingYear"
                    control={control}
                    rules={{ required: "Class 10th passing year is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={passingYears}
                        className="select-input-field"
                        placeholder="Class 10th Passing Year"
                      />
                    )}
                  />
                  {errors.passingYear && (
                    <div className="error">{errors.passingYear.message}</div>
                  )}
                </div>
                <div className="form-item">
                  <Controller
                    name="twelfthBoard"
                    control={control}
                    rules={{ required: "Class 12th board is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={educationBoards.map((board) => ({
                          value: board,
                          label: board,
                        }))}
                        className="select-input-field"
                        placeholder="Class 12th Board"
                      />
                    )}
                  />
                  {errors.twelfthBoard && (
                    <div className="error">{errors.twelfthBoard.message}</div>
                  )}
                </div>
                <div className="form-item">
                  <Controller
                    name="twelfthPercentage"
                    control={control}
                    rules={{ required: "Class 12th percentage is required" }}
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        className="select-input-field1"
                        placeholder="Enter your Class 12th percentage"
                      />
                    )}
                  />
                  {errors.twelfthPercentage && (
                    <div className="error">
                      {errors.twelfthPercentage.message}
                    </div>
                  )}
                </div>
                <div className="form-item">
                  <Controller
                    name="givenAbroadExam"
                    control={control}
                    rules={{
                      required: "Study abroad exam selection is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: "Booked", label: "Booked" },
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        className="select-input-field"
                        placeholder="Have you given any study abroad exam?"
                      />
                    )}
                  />
                  {errors.givenAbroadExam && (
                    <div className="error">
                      {errors.givenAbroadExam.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="rate-my-chance-Container2">
                <div className="ratemy-chance-form-check">
                  <input
                    type="checkbox"
                    checked={consentGiven}
                    onChange={handleCheckboxChange}
                  />
                  <p>
                    I have read and provide consent for my data to be processed
                    for purposes mentioned in the Privacy Policy and the Terms
                    and Conditions, and agree to be contacted for Education
                    related services & promotions (you can edit your preference
                    under My Profile)
                  </p>
                </div>
                <button
                  className="ratemy-chance-button"
                  type="submit"
                  disabled={!consentGiven}
                >
                  Rate My Chance
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Profile Under Review"
        className="modal-rate-mychance"
        overlayClassName="modal-overlay-rate-mychance"
      >
        <h2>Profile Under Review</h2>
        <p>
          Your profile is currently under review. We will contact you with
          further details.
        </p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
}

export default RateMyChance;
