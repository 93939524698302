import React, { useEffect, useState } from "react";
import CoursesClasses from "./Courses.module.css";
import { Services } from "../../Services";
import { ToastError } from "../../Components/utils/ToastMessages";

const Coachingpage = ({ id }) => {
  const [data, setData] = useState([]);
  const [courseInfo, setCourseInfo] = useState(null);

  const fetchData = async () => {
    try {
      const res = await Services.Coachingprograms("GET", null, id);
      if (res.Status === 1) {
        setData(res.CoachingPrograms);
        setCourseInfo(res.CoachingCourseInfo);
      } else {
        ToastError(res.Message);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {courseInfo && ( 
        <div className={CoursesClasses["textpage-container"]}>
          <div className={CoursesClasses["textpage-container-text"]}>
                <h2 className={CoursesClasses["textpage-container-menu-heading"]}>{courseInfo.ccName}</h2>
            <h3>{courseInfo.ccHeading}</h3>
            <h4>{courseInfo.ccBody}</h4>
          </div>
          {data.map((item, index) => (
            <div key={index}>
              <h2 className={CoursesClasses["textpage-heading"]}>{item.coachingExamCategoryName}</h2>
              <div className={CoursesClasses["textpage-cards"]}>
                {item.exams.map((card, index) => (
                  <div key={index} className={CoursesClasses["textpage-card"]}>
                    <img src={card.coachingExamPhoto} alt={card.coachingExamName} />
                    <div className={CoursesClasses["textpage-card-content"]}>
                      <h2>
                        {card.coachingExamName.length > 70
                          ? `${card.coachingExamName.substring(0, 70)}...`
                          : card.coachingExamName}
                      </h2>
                      <p>
                        {card.coachingExamDescription.length > 100
                          ? `${card.coachingExamDescription.substring(0, 100)}...`
                          : card.coachingExamDescription}
                      </p>
                      <div className={CoursesClasses["textpage-price-card"]}>
                        <span className={CoursesClasses["textpage-durations"]}>{card.coachingDuration}</span>
                        <p>₹{card.coachingPrice}.00</p>
                      </div>
                      <div className={CoursesClasses["span_container"]}>
                        <button type="button" className={CoursesClasses["button"]}>Pay</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Coachingpage;
